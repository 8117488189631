<template>
    <!-- <div class="data-management"> -->
    <div class="studio-page-wrapper" :style="rightContentStyle">
        <!-- <RecentActivity /> -->
        <PreDefinedFilters />
        <TableData />
        <Filters @drawerStateChanged="handleRightDrawerStateChange" />
    </div>
</template>

<script>
import PreDefinedFilters from "@/components/DataExploration/PreDefinedFilters.vue";
// import RecentActivity from "@/components/DataExploration/RecentActivity.vue";
import TableData from "@/components/DataExploration/TableData.vue";
import Filters from "@/components/layout/Common/Filters.vue";
import screenWidthMixin from "../assets/JS/screenWidthMixin";
export default {
    components: {
        // RecentActivity,
        PreDefinedFilters,
        TableData,
        Filters,
    },
    name: "DataExploration",
    mixins: [screenWidthMixin],
};
</script>