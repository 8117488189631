<template>
  <div class="chart-wrap">
    <div v-for="(chart, index) in chartTypes" :key="index" class="chart-column">
      <div v-if="loading[index]">
        <DashboardCharts></DashboardCharts>
      </div>
      <div v-else>
        <div v-if="timeoutMessage[index]" class="main-chart timeout">
          <div class="text-center">
            {{ timeoutMessage[index] }}
            <v-icon flat @click="fetchChartsData(index)" color="blue-darken-3" icon="mdi-reload" size="large"></v-icon>
          </div>
        </div>
        <div v-else-if="apiError[index]" class="main-chart timeout">
          <div class="text-center" v-html="apiError[index]"></div>
        </div>
        <div v-else-if="noData[index]" class="tabs-no-test-div">
          <h6 v-html="noData[index]"></h6>
        </div>
        <div v-else>
          <div :ref="'chart_' + index" class="main-chart">
            <div>
              <v-btn v-if="dataLoaded[index]" @click="openModal(index)" flat size="small"
                class="chart-preview-btn2 text-none">
                Expand
              </v-btn>
            </div>
          </div>

          <v-dialog v-model="isPreviewOpen[index]" width="100vw" class="multi-chart-preview-div composition">
            <v-card>
              <div class="modal-header header-div">
                <v-spacer></v-spacer>
                <v-btn @click="closeModal(index)" flat icon class="chart-preview-close-btn" size="x-small">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <v-card-text>
                <div :ref="'modal_chart_' + index" class="modal-chart-container"></div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
    <div v-if="chartTypes.length == 0" class="tabs-no-test-div">
      <h6>Chart Types are not available.</h6>
    </div>
  </div>
</template>

<script>
import api from "../../../axiosInterceptor";
import Plotly from "plotly.js-dist-min";
import DashboardCharts from "../Loaders/DashboardCharts.vue";

export default {
  name: "ChartsData",
  data() {
    return {
      tableData: [],
      loading: this.chartTypes.map(() => true),
      isPreviewOpen: this.chartTypes.map(() => false),
      dataLoaded: this.chartTypes.map(() => false),
      timeoutMessage: this.chartTypes.map(() => ""),
      apiError: this.chartTypes.map(() => ""),
      selectedColorGroupKeys: this.chartTypes.map(() => ""),
      selectedYAxisKeys: this.chartTypes.map(() => []),
      selectedXAxisKeys: this.chartTypes.map(() => []),
      selectedZAxisKeys: this.chartTypes.map(() => []),
      markerSizes: [],
      noData: this.chartTypes.map(() => ""),
    };
  },
  components: {
    DashboardCharts,
  },
  props: {
    chartTypes: {
      type: Array,
      required: true,
    },
  },
  methods: {
    async fetchChartsData(index = null) {
      if (index !== null) {
        this.clearMessages(index);
        this.loading[index] = true;

        try {
          const chart = this.chartTypes[index];
          const response = await api.get(`public/api/v1/charts/data/${chart.ID}`);

          if (response.data.statusCode === 200) {
            const rows = response.data.data.tableData.rows;
            if(rows) {
              this.tableData[index] = rows;
              this.dataLoaded[index] = true;
              this.loading[index] = false;
              this.renderChart(chart, index, "chart_");
            }
            else {
              this.loading[index] = false;
              this.noData[i] = "no data available to plot chart."
            }
          }
        } catch (error) {
          this.handleError(index, error);
        }
      } else {
        this.clearAllMessages();
        this.loading = this.chartTypes.map(() => true);

        for (let i = 0; i < this.chartTypes.length; i++) {
          const chart = this.chartTypes[i];
          try {
            const response = await api.get(`public/api/v1/charts/data/${chart.ID}`);

            if (response.data.statusCode === 200) {
            const rows =  response.data.data.TableData.rows;
            if(rows) {
              this.tableData[i] = rows;
              this.dataLoaded[i] = true;
              this.loading[i] = false;
              this.renderChart(chart, i, "chart_");
            }
            else {
              this.loading[i] = false;
              this.noData[i] = "no data available to plot chart."
            }
            }
          } catch (error) {
            this.handleError(i, error);
          }
        }
      }
    },

    openModal(index) {
      this.isPreviewOpen[index] = true;
      this.$nextTick(() => {
        this.renderChart(this.chartTypes[index], index, "modal_chart_");
      });
    },

    closeModal(index) {
      this.isPreviewOpen[index] = false;
    },

    renderChart(chart, index, refPrefix) {
      this.$nextTick(() => {
        const chartRef = this.$refs[refPrefix + index];
        if (!chartRef || !chartRef.length) return;

        const chartDiv = chartRef[0];
        if (!chartDiv) return;

        const traces = this.generateTraces(chart, index);
        const layout = this.generateLayout(chart, index);
        const config = this.generateConfig(index);

        Plotly.newPlot(chartDiv, traces, layout, config);
      });
    },

    generateTraces(chart, index) {
      const traceType = this.getTraceType(chart);
      const traceMode = this.getTraceMode(chart);
      const scatterMarkerSizes = 8;
      const yColumns = chart.YAxisColumn.split(",");
      this.selectedYAxisKeys[index] = yColumns;

      if (chart.GroupColumn && chart.YAxisColumn) {
        yColumns.pop();
        this.selectedColorGroupKeys[index] = chart.GroupColumn;
        this.selectedXAxisKeys[index] = chart.XAxisColumn;
        this.selectedZAxisKeys[index] = chart.ZAxisColumn;

        const colorGroupData = this.tableData[index].map(item => item[chart.GroupColumn]);
        const uniqueValues = [...new Set(colorGroupData.filter(item => item !== undefined && item !== null))];

        const groupedYAxisData = {};
        const groupedXAxisData = {};
        const groupedZAxisData = {};

        uniqueValues.forEach(value => {
          groupedYAxisData[value] = [];
          groupedXAxisData[value] = [];
          groupedZAxisData[value] = [];
        });

        this.tableData[index].forEach(row => {
          const colorGroupValue = row[this.selectedColorGroupKeys[index]];

          if (uniqueValues.includes(colorGroupValue)) {
            const yAxisRowData = this.selectedYAxisKeys[index].map(key => row[key]);
            groupedYAxisData[colorGroupValue].push(yAxisRowData);

            const xAxisRowData = row[this.selectedXAxisKeys[index]];
            groupedXAxisData[colorGroupValue].push(xAxisRowData);

            const zAxisRowData = row[this.selectedZAxisKeys[index]];
            groupedZAxisData[colorGroupValue].push(zAxisRowData);
          }
        });

        return Object.keys(groupedXAxisData).map((key) => {
          const xData = groupedXAxisData[key];
          let yData = groupedYAxisData[key];
          const zData = groupedZAxisData[key];
          this.markerSizes = [...zData];

          if (!Array.isArray(xData) || !Array.isArray(yData) || xData.length !== yData.length) {
            return null;
          }

          yData = yData.map(item => item[0]);

          const markerSize = (chart.ChartType === "Scatter Plot")
            ? (scatterMarkerSizes)
            : (this.markerSizes);

          const trace = {
            x: xData,
            y: yData,
            z: zData,
            type: traceType,
            mode: traceMode,
            name: key,
            marker: {
              size: markerSize
            },
          };

          if (chart.ChartType === "Bubble" || chart.ChartType === "3D Scatter") {
            const maxMarkerSize = Math.max(...this.markerSizes);
            trace.marker = {
              ...trace.marker,
              sizemode: 'area',
              sizeref: 2.0 * maxMarkerSize / (80 * 2), // Adjusted size reference for better fitting
              sizemin: 2,
            };
          }

          return trace;
        }).filter(trace => trace !== null);
      } else {
        const xData = this.tableData[index].map(item => item[chart.XAxisColumn]);
        const yData = yColumns.map(col => this.tableData[index].map(item => item[col]));
        const zData = this.tableData[index].map(item => item[chart.ZAxisColumn]);

        this.markerSizes = [...zData];

        const markerSize = (chart.ChartType === "Scatter Plot")
          ? (scatterMarkerSizes)
          : (this.markerSizes);

        return yData.map((y, i) => {
          const trace = {
            x: xData,
            y: y,
            z: zData,
            type: traceType,
            mode: traceMode,
            name: yColumns[i],
            marker: {
              size: markerSize
            },
          };

          if (chart.ChartType === "Bubble" || chart.ChartType === "3D Scatter") {
            const maxMarkerSize = Math.max(...this.markerSizes);
            trace.marker = {
              ...trace.marker,
              sizemode: 'area',
              sizeref: 2.0 * maxMarkerSize / (80 * 2), // Adjusted size reference for better fitting
              sizemin: 2,
            };
          }

          return trace;
        });
      }
    },

    generateLayout(chart, index) {
      const yColumns = chart.YAxisColumn.split(",");
      if (chart.GroupColumn && chart.YAxisColumn) {
        yColumns.pop(); // Remove the last Y-axis column if GroupColumn is present
      }
      return {
        title: `${chart.Name} <br> (${chart.ChartType})`,
        xaxis: { title: chart.XAxisColumn },
        yaxis: { title: yColumns.join(", ") },
        legend: {
          title: {
            text: this.selectedColorGroupKeys[index] ? this.selectedColorGroupKeys[index] : ''
          }
        },
        updatemenus: [
          {
            buttons: [
              {
                label: "Download CSV",
                method: "downloadCsv",
              },
            ],
          },
        ],
      };
    },

    generateConfig(index) {
      return {
        responsive: true,
        displaylogo: false,
        modeBarButtonsToRemove: ["lasso2d", "select2d", "pan2d"],
        modeBarButtonsToAdd: [
          {
            name: "Download CSV",
            icon: {
              path: "M12 15.5l-5-5h3v-6h4v6h3l-5 5zm-10 3h20v2h-20z",
            },
            click: this.handleDownloadCSV.bind(this, index),
          },
        ],
      };
    },

    getTraceType(chart) {
      switch (chart.ChartType) {
        case "Line Chart": return "scatter";
        case "Bar Chart": return "bar";
        case "Histogram": return "histogram";
        case "Bubble": return "scatter";
        case "3D Scatter": return "scatter3d";
        case "Scatter Plot": return "scatter";
        case "2D Histogram": return "histogram2d";
        case "Heatmap": return "heatmap";
        case "Treemap": return "treemap";
        default: return "scatter";
      }
    },

    getTraceMode(chart) {
      if (chart.ChartType === "Bubble") return "markers";
      if (chart.ChartType === "Scatter Plot") return "markers";
      return undefined;
    },

    handleDownloadCSV(index) {
      const chartData = this.tableData[index];
      const csvContent = this.convertToCsv(chartData);
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const fileName = `chart_data_${index}.csv`;

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    convertToCsv(data) {
      const csvRows = [];
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(","));

      for (const row of data) {
        const values = headers.map(header => JSON.stringify(row[header], (key, value) => value === null ? "" : value));
        csvRows.push(values.join(","));
      }

      return csvRows.join("\n");
    },

    clearMessages(index) {
      this.timeoutMessage[index] = "";
      this.apiError[index] = "";
    },

    clearAllMessages() {
      this.timeoutMessage = this.chartTypes.map(() => "");
      this.apiError = this.chartTypes.map(() => "");
    },

    handleError(index, error) {
      this.loading[index] = false;
      this.timeoutMessage[index] = error.message || "An error occurred";
      if (error.response && error.response.status) {
        this.apiError[index] = "Api Error";
      }
    },
  },

  mounted() {
    this.fetchChartsData();
  },
};
</script>