<template>
  <div class="studio-page-wrapper" :style="rightContentStyle">
    <div class="top-wrap">
      <div class="text-img">
        <h2> Multi Stage Separator Test </h2>
        <div v-if="receivedData">
          <div v-if="pdfLoader === true" class="test-pdf-div">
            <img :src="pdf_loader_image" alt="loader Image" width="20" height="20" />
          </div>
          <div v-else-if="receivedData.testID !== ''" @click="goToPdf()" class="test-pdf-div">
            <img :src="pdf_icon" alt="pdf icon" width="20" height="20" />
            <div class="pdf-text">Download PDF</div>
          </div>
        </div>
      </div>
      <div class="top-content" v-if="receivedData && receivedData.testID != ''">
        <v-avatar size="56" rounded="0">
          <v-img src="../../../assets/Images/Assets/top image.png" alt="test image"></v-img>
        </v-avatar>
        <v-row no-gutters>
          <v-col cols="12" md="6" lg="6">
            <p>Test ID
              <span class="test-top-values">{{ receivedData.testID }}</span>
            </p>
          </v-col>
          <v-col cols="12" md="6" lg="6" v-if="receivedData.sampleID">
            <p>Sample ID
              <span class="test-top-values">{{ receivedData.sampleID }}</span>
            </p>
          </v-col>
          <v-col cols="12" md="6" lg="6" v-if="receivedData.saturationPressure_UOM && receivedData.saturationPressure">
            <p>Saturation Pressure
              <span class="test-top-values">{{
                receivedData.saturationPressure }}
                <span> ({{
                  receivedData.saturationPressure_UOM }})
                </span>
              </span>
            </p>
          </v-col>
          <v-col cols="12" md="6" lg="6" v-if="receivedData.testTemperature && receivedData.testTemperature_UOM">
            <p>Test Temperature
              <span class="test-top-values">{{
                receivedData.testTemperature }}
                <span> ({{
                  receivedData.testTemperature_UOM }})
                </span>
              </span>
            </p>
          </v-col>
        </v-row>
        <div v-if="receivedData.testID == ''">
          No data available.
        </div>
      </div>
      <div class="top-content" v-else>
        <test-loaders />
      </div>
    </div>
    <div class="tabs-data" ref="scrollable" tabindex="0" @keydown="handleKeyDown">
      <v-tabs v-model="tab">
        <v-tab value="one">Multi Stage Seperator Test</v-tab>
        <v-tab value="two">Liberated Gas Compositions</v-tab>
        <v-tab value="three">Residual Oil Compositions</v-tab>
      </v-tabs>

      <v-card-text class="pa-0 pt-4">
        <v-window v-model="tab">
          <v-window-item value="one">
            <SeparatorTestData @data-updated="handleDataUpdate" />
          </v-window-item>

          <v-window-item value="two">
            <LiberatedGasCompositon />
          </v-window-item>

          <v-window-item value="three">
            <ResidualOilComposition />
          </v-window-item>
        </v-window>
      </v-card-text>
    </div>
    <v-snackbar v-model="snackbarMessage" color="#E53535" timeout="3000" v-if="snackbarMessage != null">
      Error : {{ snackbarMessage }}
    </v-snackbar>
    <Filters @drawerStateChanged="handleRightDrawerStateChange" />
  </div>
</template>

<script>
import SeparatorTestData from "../MultiStageSeperatorTest/SeparatorTestData.vue";
import TestLoaders from '../Loaders/TestLoaders.vue';
import LiberatedGasCompositon from './LiberatedGasCompositon.vue';
import ResidualOilComposition from './ResidualOilComposition.vue';
import Filters from "../../../components/layout/Common/Filters.vue";
import screenWidthMixin from "../../../assets/JS/screenWidthMixin";
import axios from "axios";
export default {
  components: { SeparatorTestData, TestLoaders, LiberatedGasCompositon, ResidualOilComposition, Filters },
  name: "DataTabs",
  data() {
    return {
      tab: null,
      receivedData: null,
      pdf_icon: require("../../../assets/Images/pdfIcon.png"),
      pdf_loader_image: require("../../../assets/Images/pdfLoader.gif"),
      pdfLoader: false,
      snackbarMessage: null,
    };
  },
  mixins: [screenWidthMixin],
  methods: {
    handleDataUpdate(data) {
      this.receivedData = data;
    },
    handleKeyDown(event) {
      const key = event.key;
      if (key === 'ArrowDown') {
        // Handle scrolling down
        this.$refs.scrollable.scrollTop += 50; // You can adjust the scrolling speed as needed
      } else if (key === 'ArrowLeft') {
        // Handle switching to the previous tab
        const currentIndex = this.tab ? ['one', 'two', 'three'].indexOf(this.tab) : 0;
        this.tab = currentIndex === 0 ? 'three' : ['one', 'two', 'three'][currentIndex - 1];
      } else if (key === 'ArrowRight') {
        // Handle switching to the next tab
        const currentIndex = this.tab ? ['one', 'two', 'three'].indexOf(this.tab) : 0;
        this.tab = currentIndex === 2 ? 'one' : ['one', 'two', 'three'][currentIndex + 1];
      }
    },
    goToPdf() {
      this.snackbarMessage = null;
      this.pdfLoader = true;
      const tenantId = localStorage.getItem("tenantId");
      const sampleId = localStorage.getItem("sampleId");
      axios
        .get(process.env.VUE_APP_API_URL + `public/api/v1/samples/${sampleId}/download`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "X-TenantID": tenantId,
          },
          responseType: 'blob',
        })
        .then((response) => {
          if (response.data) {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            this.pdfLoader = false;
            window.open(url, '_blank');
          }
        })
        .catch((error) => {
          if (error.response) {
            this.snackbarMessage = "PDF data is not available."
            this.pdfLoader = false;
          }
        });
    },
  },
  mounted() {
    this.tab = 'one';
    this.$refs.scrollable.focus();
  },
};
</script>