<template>
  <div>
    <div>
      <v-switch v-if="serverItems.length > 0 && showLoaders == false" v-model="toggleFraction" color="info"
        :label="toggleFraction ? 'Mass %' : 'Mole %'" @change="toggleFractionFunction"></v-switch>
    </div>
    <chartLoaders v-if="showLoaders == true"></chartLoaders>
    <screen-loader v-if="showLoaders == true"></screen-loader>
    <v-card-text class="pa-0" v-else-if="serverItems.length > 0 && showLoaders == false">
      <!-- <v-select
        v-model="selectedSort"
        label="Sort Step Pressure"
        :items="sortOptions"
        @update:modelValue="onSelectSort"
      ></v-select> -->
      <div class="chart-wrap" v-if="hasStepPressure">
        <div v-for="(variable, index) in variables" :key="index" class="chart-column">
          <div :ref="`chart-${index}`" class="main-chart">
            <div v-if="!loading">
              <v-btn v-if="!loading && serverItems.length > 0" @click="openModal(index)" flat size="small"
                class="chart-preview-btn2 text-none">
                Expand
              </v-btn>
            </div>
          </div>

          <v-dialog v-model="isPreviewOpen[index]" width="100vw" class="multi-chart-preview-div">
            <v-card>
              <div class="modal-header header-div">
                <v-spacer></v-spacer>
                <v-btn @click="closeModal(index)" flat icon class="chart-preview-close-btn" size="x-small">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <v-card-text>
                <div :ref="`modal-chart-${index}`" class="modal-chart-container"></div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <div v-else-if="!hasStepPressure" class="text-center pt-3 pb-5">
        <h4>Charts couldn't be plotted as step pressure is not available in this test.</h4>
      </div>
      <div class="table-wrap">
        <div class="table-title-div" v-if="serverItems.length > 0">
          <p class="table-title">Residual Oil Compositions</p>
          <div class="csv-btn" @click="downloadTableCSV">
            <img class="pointer" :src="documentDownload" alt="" />
            <div>
              Download CSV
            </div>
          </div>
        </div>
        <v-data-table :headers="headers" :items="serverItems" :total-items="totalItems" :items-per-page="itemsPerPage"
          :loading="loading" :search="search" :item-value="name" virtual-items>
          <template v-slot:headers>
            <tr class="main-header">
              <th v-for="(header, index) in headers" :key="index">
                {{ header.name }}
                <span @click="handleHeaderClick(header.key)">
                  <span v-if="header.key === 'stepPressure'">
                    <v-icon v-if="sortOrder === 'asc'" @click.stop="sortStepPressure('desc')"
                      small>mdi-arrow-down</v-icon>
                    <v-icon v-else @click.stop="sortStepPressure('asc')" small>mdi-arrow-up</v-icon>
                  </span>
                </span>
              </th>
            </tr>
            <tr class="sub-header">
              <th v-for="(header, index) in headers" :key="index">
                {{ header.unit }}
              </th>
            </tr>
          </template>
          <template v-slot:body="{ items }">
            <tr v-for="(item, index) in items" :key="index">
              <td v-for="(header, headerIndex) in headers" :key="headerIndex">
                <template v-if="header.key === 'saturationPressure'">
                  <template v-if="index === 0">{{ displayValue(header.key, item[header.key]) }}</template>
                  <template v-else>-</template>
                </template>
                <template v-else>
                  {{ displayValue(header.key, item[header.key]) !== null ? displayValue(header.key, item[header.key]) :
                    '-'
                  }}
                </template>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card-text>
    <div v-else-if="(!serverItems || serverItems.length === 0) && showLoaders == false" class="tabs-no-test-div">
      <h4>No data available.</h4>
    </div>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist-min";
import ChartLoaders from "../Loaders/ChartLoaders.vue";
import ScreenLoader from "../Loaders/ScreenLoader.vue";
import axios from "axios";
// import { mapActions } from "vuex";
import { getDisplayName } from '../../../utils/cache';

export default {
  name: "ResidualOilCompositon",
  components: {
    ChartLoaders,
    ScreenLoader,
  },
  data: () => ({
    showLoaders: true,
    documentDownload: require("../../../assets/Images/csv download.png"),
    itemsPerPage: 0,
    headers: [],
    serverItems: [],
    loading: true,
    totalItems: 0,
    tenantId: localStorage.getItem("tenantId"),
    variables: ["1", "2"],
    isPreviewOpen: Array().fill(false),
    chartData: Array().fill(null),
    cachedData: {},
    testID: "",
    testName: "",
    sampleID: "",
    hasStepPressure: false,
    saturationPressure: "",
    saturationPressure_UOM: "",
    testTemperature: "",
    testTemperature_UOM: "",
    toggleFraction: true,
    //   selectedSort: "High to Low",
    // sortOptions: ["High to Low", "Low to High"],
    sortOrder: 'desc'
  }),
  methods: {
    //   onSelectSort() {
    //   const sortOrder = this.selectedSort === "High to Low" ? 0 : 1; // Set sort order based on selection

    //   this.loadItems({ sortOrder })
    //     .then((ccetest) => {
    //       this.variables.forEach((variable, index) => {
    //         this.plotChartForVariable(variable, index, ccetest);
    //       });
    //     })
    //     .catch((error) => {
    //       console.error("Error loading data:", error);
    //     });
    // },
    sortStepPressure(order) {
      this.sortOrder = order;
      if (order === 'asc') {
        this.serverItems.sort((a, b) => a.stepPressure - b.stepPressure);
      } else {
        this.serverItems.sort((a, b) => b.stepPressure - a.stepPressure);
      }
    },
    toggleFractionFunction() {
      this.serverItems = [];
      if (this.loading == true && this.serverItems.length == 0) {
        this.showLoaders = true;
      }
      this.chartPlotted();
    },
    downloadChartCSV(index) {
      const chartData = this.chartData[index];

      // Check if chart data exists
      if (chartData) {
        const fileName = `${chartData.layout.yaxis.title.text} vs ${chartData.layout.xaxis.title.text} data.csv`;

        // Prepare CSV header dynamically
        const csvHeader = [chartData.layout.xaxis.title.text, chartData.layout.yaxis.title.text];

        // Prepare CSV rows
        const csvRows = chartData.trace.x.map((xValue, i) => [xValue, chartData.trace.y[i]]);

        // Concatenate header and rows
        const csvContent = [csvHeader.join(','), ...csvRows.map(row => row.join(','))].join('\n');

        // Create Blob with CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        // Create link element to trigger download
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', fileName); // Set filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error('Your browser does not support downloading files programmatically. Please try a different browser.');
        }
      }
    },

    downloadTableCSV() {
      // Check if table has items
      if (this.serverItems && this.serverItems.length > 0) {
        const fileName = `Multistage_seperator.csv`;

        // Prepare CSV header dynamically from table headers
        const csvHeader = this.headers.map((header) => header.name);
        const csvUnits = this.headers.map((header) => header.unit);

        // Prepare CSV rows from table data
        const csvRows = this.serverItems.map((item) =>
          this.headers.map((header) => item[header.key])
        );

        // Concatenate header and rows
        const csvContent = [
          csvHeader.join(","),
          csvUnits.join(","),
          ...csvRows.map((row) => row.join(",")),
        ].join("\n");

        // Create Blob with CSV content
        const blob = new Blob([csvContent], {
          type: "text/csv;charset=utf-8;",
        });

        // Create link element to trigger download
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName); // Set filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error(
            "Your browser does not support downloading files programmatically. Please try a different browser."
          );
        }
      } else {
        console.error("No data available in the table to download.");
      }
    },

    async loadItems() {
      var sampleId = localStorage.getItem("sampleId");
      var separatorId = localStorage.getItem("testId");
      this.loading = true;
      this.showLoaders = true;

      try {
        let response = await axios.get(
          process.env.VUE_APP_API_URL +
          `public/api/v1/samples/${sampleId}/separatortests/${separatorId}`,
          {
            //   params: {
            //   sortOrder: this.selectedSort === "High to Low" ? 0 : 1, // Add sortOrder parameter
            // },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": this.tenantId,
            },
          }
        );

        this.serverItems = response.data.data.separatortest && response.data.data.separatortest.separatorResidComposition ? response.data.data.separatortest.separatorResidComposition : [];
        this.totalItems = response.data.data.separatortest && response.data.data.separatortest.length ? response.data.data.separatortest.length : 0;
        this.testID = response.data.data.separatortest.ID
        this.testName = response.data.data.separatortest.name
        this.sampleID = response.data.data.separatortest.sampleID
        this.saturationPressure = response.data.data.separatortest.saturationPressure
        this.saturationPressure_UOM = response.data.data.separatortest.saturationPressure_UOM
        this.testTemperature = response.data.data.separatortest.testTemperature
        this.testTemperature_UOM = response.data.data.separatortest.testTemperature_UOM
        this.sendDataToParent();

        //   if (this.selectedSort === "High to Low") {
        //   this.serverItems.sort((a, b) => b.stepPressure - a.stepPressure);
        // } else {
        //   this.serverItems.sort((a, b) => a.stepPressure - b.stepPressure);
        // }


        const sortByArray = this.toggleFraction ? 'sortByMass' : 'sortByMole';

        const sortByKeys = response.data.data.separatortest.separatorResidComposition && response.data.data.separatortest.separatorResidComposition.length > 0 ? response.data.data.separatortest.separatorResidComposition.map(item => item[sortByArray])[0]?.flat()?.map(key => key.charAt(0).toLowerCase() + key.slice(1)) : [];

        //   const sortByKeys = response.data.data.separatortest.sortByOil.map(key =>
        //     key.charAt(0).toLowerCase() + key.slice(1)
        //   );
        // Check if serverItems contains stepPressure
        this.hasStepPressure = this.serverItems.some(item => item.hasOwnProperty('stepPressure'));

        const availableKeys = new Set(
          sortByKeys.filter((key) =>
            this.serverItems.some((item) => key in item)
          )
        );


        const allKeys = sortByKeys.filter((key) => availableKeys.has(key));

        // Update variables array with extracted keys, excluding stepPressure & stepTemperature
        //   this.variables = Array.from(allKeys).filter(key => key !== 'stepPressure' && key !== 'stepTemperature');
        this.variables = Array.from(allKeys).filter(
          (key) =>
            (key !== "stepPressure" &&
              key !== "stepTemperature" &&
              this.toggleFraction == true &&
              key.includes("MassFraction")) ||
            (this.toggleFraction == false && key.includes("MoleFraction"))
        );

        const allUniqueKeys = Array.from(allKeys);

        const displayNames = [];
        const digitsArray = [];

        await Promise.all(allUniqueKeys.map(async (key) => {
          const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
          const { displayName, digits } = await getDisplayName('SeparatorResidComposition', capitalizedKey);
          displayNames.push(displayName);
          digitsArray.push(digits);
        }));

        // Update the headers array to use display names
        this.headers = Array.from(allKeys).map((key, index) => {
          const uom = this.serverItems.find((item) => item[key + "_UOM"]);
          const name = displayNames[index]; // Use display name instead of key
          let unit = uom ? uom[key + "_UOM"] : '-';
          // Check if unit is empty or equals "data-not-available", then set it to "-"
          if (!unit || unit === "data-not-available") {
            unit = "-";
          }
          const digits = digitsArray[index] || 0;
          return {
            key: key,
            name: name,
            unit: unit,
            digits: digits,
          };
        });

        return response.data.data.separatortest;
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
        this.showLoaders = false;
      }
    },

    async plotChartForVariable(variable, chartIndex, separatortest) {
      if (!separatortest || !separatortest.separatorTestSteps) {
        return;
      }

      const stepPressures = separatortest.separatorTestSteps.map((step) => step.stepPressure);

      const trace = {
        x: stepPressures,
        y: separatortest.separatorTestSteps.map((step) => step[variable]),
        type: "scatter",
        mode: "lines+markers",
        name: variable,
      };

      const layout = {
        title: {
          text: `${this.getHeaderDisplayName(variable)} vs Pressure`,
          font: {
            size: 12,
          },
        },
        xaxis: { title: "Pressure (psia)" },
        yaxis: {
          title: this.getHeaderDisplayName(variable)
        },
      };
      const config = {
        responsive: true,
        displaylogo: false,
        modeBarButtonsToRemove: ['lasso2d', 'select2d', 'pan2d'],
        modeBarButtonsToAdd: [
          {
            name: 'Download CSV',
            icon: {
              path: 'M12 15.5l-5-5h3v-6h4v6h3l-5 5zm-10 3h20v2h-20z',
            },
            click: this.handleDownloadCSV.bind(this, chartIndex)
          }
        ]
      };

      this.chartData[chartIndex] = {
        trace: trace,
        layout: layout,
        config: config,
      };

      const chartRef = this.$refs["chart-" + chartIndex];
      if (chartRef && chartRef.length > 0) {
        const chartElement = chartRef[0];
        Plotly.newPlot(chartElement, [trace], layout, config);
      } else {
        return;
      }
    },

    getHeaderDisplayName(variable) {
      const header = this.headers.find(header => header.key === variable);
      if (header) {
        return header.unit !== '-' ? `${header.name} (${header.unit})` : header.name;
      }
      return variable;
    },

    handleDownloadCSV(chartIndex) {
      this.downloadChartCSV(chartIndex);
    },

    displayValue(key, value) {
      const header = this.headers.find(header => header.key === key);
      const digits = header ? header.digits : 0; // Get digits from header

      if (value !== null && value !== undefined) {
        // Format the value with the specified number of decimal places
        return digits > 0 ? value.toFixed(digits) : value;
      } else {
        return "-";
      }
    },

    openModal(index) {
      this.isPreviewOpen[index] = true;
      this.$nextTick(() => {
        this.plotModalChart(index);
      });
    },
    closeModal(index) {
      this.isPreviewOpen[index] = false;
    },
    plotModalChart(index) {
      const chartData = this.chartData[index];
      if (chartData) {
        const modalChartRef = this.$refs[`modal-chart-${index}`][0];
        Plotly.newPlot(
          modalChartRef,
          [chartData.trace],
          chartData.layout,
          chartData.config
        );
      }
    },
    sendDataToParent() {
      this.$emit('data-updated', {
        testID: this.testID,
        testName: this.testName,
        sampleID: this.sampleID,
        saturationPressure: this.saturationPressure,
        saturationPressure_UOM: this.saturationPressure_UOM,
        testTemperature: this.testTemperature,
        testTemperature_UOM: this.testTemperature_UOM,
      });
    },
    chartPlotted() {
      this.loadItems().then((ccetest) => {
        // Determine which variables to plot based on toggle state
        const variablesToPlot = [];
        if (this.toggleFraction == true) {
          variablesToPlot.push("FluidComponentReference", "vapor_MassFraction");
        }
        if (this.toggleFraction == false) {
          variablesToPlot.push("FluidComponentReference", "vapor_MoleFraction");
        }

        if (
          JSON.stringify(variablesToPlot) !==
          JSON.stringify(this.variablesToPlot)
        ) {
          this.variablesToPlot = variablesToPlot; // Update the tracked variables to plot
          this.plotCharts(variablesToPlot, ccetest); // Plot charts only for the variables enabled by the toggle switches
        }

        // Plot charts only for the variables enabled by the toggle switches
        variablesToPlot.forEach((variable, index) => {
          this.plotChartForVariable(variable, index, ccetest);
        });
      });
    },
    plotCharts(variablesToPlot, ccetest) {
      variablesToPlot.forEach((variable, index) => {
        this.plotChartForVariable(variable, index, ccetest);
      });
    },
  },
  watch: {
    $route(to, from) {
      // Call the loadItems method when route parameters change
      this.loadItems()
        .then((separatortest) => {
          this.variables.forEach((variable, index) => {
            this.plotChartForVariable(variable, index, separatortest);
          });
        })
        .catch((error) => {
          console.error("Error loading data:", error);
          // You may want to handle errors here
        });
    },
  },
  mounted() {
    this.chartPlotted();
  },
};
</script>