<template>
  <v-row no-gutters class="ma-0">
    <v-col v-for="index in numberOfCols" :key="index" cols="12">
      <v-skeleton-loader type="list-item-two-line" class="px-3"> </v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TabularLoaders",
  data() {
    return {
      numberOfCols: 8,
    };
  },
};
</script>
