import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import axios from "axios";
import "../src/assets/Styling/Studio.css"
import "../src/assets/Styling/style.css"
import "../src/assets/Styling/newstyle.css"
import "../src/assets/Styling/leftmenu.css"
import "../src/assets/Styling/classes.css"
import "../src/assets/Styling/variable.css"
import "../src/assets/Styling/responsive.css"
import "../src/assets/Fonts/PtSans.css"
import "../src/assets/Fonts/Mulish.css"
import "../src/assets/Fonts/Inter.css"
import "../src/assets/Fonts/Roboto.css"
import "../src/assets/Fonts/Outfit.css"
import router from './router'
import store from './store'
import './axiosInterceptor';
loadFonts()

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


store.dispatch('test/initializeActiveTestIndex');

const app = createApp(App);
app.use(vuetify);
app.use(router);
app.use(store);
app.component('VueDatePicker', VueDatePicker);

const snackbar = createApp({
  data() {
    return {
      message: '',
      showSnackbar: false,
      snackbarColor: 'success',
    }
  },
  methods: {
    showOnlineSnackbar() {
      this.message = 'Back Online';
      this.showSnackbar = true;
    },
    showOfflineSnackbar() {
      this.message = 'You are currently offline. Please check your internet connection.';
      this.showSnackbar = true;
    }
  },
  mounted() {
    window.addEventListener('offline', this.showOfflineSnackbar);
    window.addEventListener('online', this.showOnlineSnackbar);
  },
}).mount('#snackbar');

app.mount('#app');

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
