export default {
  data() {
    return {
      drawerOpen: true,
      rightDrawerOpen: false,
      screenWidth: window.innerWidth,
    };
  },
  computed: {
    rightSubContentStyle() {
      let width = "calc(100vw - 64px)";
      let left = "64px";
      let right = "64px";

      if (this.screenWidth >= 768) {
        if (this.drawerOpen) {
          left = "280px";
          width = "calc(100vw - 280px)";
        }
      }

      return {
        width: width,
        left: left,
        right: right,
      };
    },
    rightContentStyle() {
      let width = "calc(100% - 90px)";

      if (this.screenWidth >= 768) {
        if (this.rightDrawerOpen){
        width = "calc(100% - 310px)";
      }
      }

      return {
        width: width,
      };
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleDrawerStateChange(isOpen) {
      if (this.screenWidth >= 768) {
        this.drawerOpen = isOpen;
      }
    },
    handleRightDrawerStateChange(isOpen) {
      console.log("handleRightDrawerStateChange called with", isOpen);
      if (this.screenWidth >= 768) {
        this.rightDrawerOpen = isOpen;
        this.$forceUpdate();
      }
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
  },
};