<template>
  <div>
    <v-btn variant class="delete-btn" @click="dashboardDelete()">
      <img :src="deleteDashboard" alt="delete_dashboard" />
      <span>Delete</span>
    </v-btn>
    <v-dialog
      transition="dialog-top-transition"
      class="save-dashboard"
      width="600"
      v-model="showDeleteConfirmation"
    >
      <!-- <v-alert title="Delete Dashboard" text="Are you sure you want to delete dashboard ?">
                <v-btn variant="tonal" class="text-none" @click="closeDeleteConfirmation">Cancel</v-btn>
                <v-btn color="error" class="text-none" text @click="confirmDeleteDashboard" :loading="loading">Delete</v-btn>
            </v-alert> -->
      <template v-slot:default="{ isActive }">
        <v-card>
          <v-card-title class="d-flex align-center justify-between">
            <v-spacer></v-spacer>
            <v-btn
              @click="closeDeleteConfirmation"
              flat
              icon
              class="chart-preview-close-btn"
              size="x-small"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="mt-4 mx-5">
            <h6 class="text-center">Delete Dashboard</h6>
            <p class="text-center">
              Are you sure you want to delete dashboard?
            </p>
          </v-card-text>
          <v-card-actions class="btn-wrap">
            <v-btn class="cancel-btn" @click="isActive.value = false">
              <span>Cancel</span>
            </v-btn>
            <v-btn
              class="delete-btn"
              @click="confirmDeleteDashboard"
              :loading="loading"
            >
              <img class="mr-2" :src="deleteDashboard" alt="delete_dashboard" />
              <span>Delete</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "DeleteDashboard",
  data() {
    return {
      deleteDashboard: require("../../../assets/Images/Dashboard/trash.png"),
      showDeleteConfirmation: false,
      loading: false,
    };
  },
  props: {
    dashboard_id: {
      type: String,
    },
  },
  methods: {
    dashboardDelete() {
      this.showDeleteConfirmation = true; // Show the confirmation alert
    },

    closeDeleteConfirmation() {
      this.showDeleteConfirmation = false; // Hide the alert
    },
    confirmDeleteDashboard() {
      const tenantId = localStorage.getItem("tenantId");
      const dashboardId = this.dashboard_id;

      this.loading = true;

      axios
        .delete(
          process.env.VUE_APP_API_URL +
            "public/api/v1/dashboards/" +
            dashboardId,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": tenantId,
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.loading = false;
            this.showDeleteConfirmation = false;
            localStorage.removeItem("lastVisitedDashboard");
            localStorage.removeItem("dashboard")
            // this.$router.push({
            //   name: "Main",
            // });
            this.$emit("dashboard-delete");
          } else {
            console.error("Error deleting dashboard:", response.data.message);
          }
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED") {
            this.timeoutMessage = `Request timeout. Try again`;
          }
          this.loading = false;
        });
    },
  },
};
</script>