<template>
  <div :class="wrapperClass" :style="rightContentStyle">
    <StudioDataLeft :selectedChartType="selectedChartType" @chart-type-changed="handleChartTypeChange"
      @loading-state="handleLoading" @chart-saved="handleChartSaved" @chart-title="handleChartTitle"
      @x-axis-selected="handleXAxisSelected" @y-axis-selected="handleYAxisSelected"
      @z-axis-selected="handleZAxisSelected" @chart-saved-studio="handleSavedCharts" @update-x-bins="updateXBins"
      @update-y-bins="updateYBins" @grouped-x-axis-data=handleXColorGroupSelected
      @grouped-y-axis-data=handleYColorGroupSelected @grouped-z-axis-data=handleZColorGroupSelected
      @color-group-key=handleColorGroupKey @sample-error="handleSampleError"
      @update-right-content-style="updateRightContentStyle" />
    <DynamicChart :loading="loading" :chartType="selectedChartType" :xAxisData="xAxisData" :yAxisData="yAxisData"
      :zAxisData="zAxisData" :markerSizes="markerSizes" :xAxisDisplayName="xAxisDisplayName"
      :yAxisDisplayNames="yAxisDisplayNames" :zAxisDisplayName="zAxisDisplayName" :chartName="chartName"
      :xBins="xBins" :yBins="yBins" :xColorGroupData="xColorGroupData" :yColorGroupData="yColorGroupData"
      :zColorGroupData="zColorGroupData" :colorGroupKey="colorGroupKey" :sampleError="sampleError" />
  </div>
</template>

<script>
import StudioDataLeft from './StudioDataLeft.vue';
import DynamicChart from '../Charts/DynamicChart.vue';
import { ref } from "vue";

export default {
  name: 'StudioData',
  components: {
    StudioDataLeft,
    DynamicChart
  },
  data() {
    return {
      selectedChartType: "",
      xAxisData: ref([]),
      yAxisData: ref([]),
      zAxisData: ref([]),
      xColorGroupData: ref([]),
      yColorGroupData: ref([]),
      zColorGroupData: ref([]),
      markerSizes: ref([]),
      xAxisDisplayName: "",
      yAxisDisplayNames: ref([]),
      zAxisDisplayName: "",
      loading: false,
      chartName: "",
      xBins: 10,
      yBins: 10,
      colorGroupKey: "",
      sampleError: "",
      rightContentStyle: {}
    };
  },
  computed: {
    wrapperClass() {
      if (this.$route.path === '/Visualization-&-Dashboards') {
        return 'dashboard-wrapper';
      } else if (this.$route.path === '/Fluidsdata-studio') {
        return 'studio-page-wrapper';
      } else {
        return '';
      }
    }
  },
  methods: {
    updateRightContentStyle(style) {
      this.rightContentStyle = style;
    },
    updateXBins(newVal) {
      this.xBins = newVal;
    },
    updateYBins(newVal) {
      this.yBins = newVal;
    },
    handleChartTitle(chartTitle) {
      this.chartName = chartTitle;
    },
    handleLoading(showloaders) {
      this.loading = showloaders;
    },
    handleChartSaved(chartSaved) {
      this.chartSaved = chartSaved;
      // this.$emit("chart-saved-studio", chartSaved);
      this.$emit("save-chart", chartSaved);
    },
    handleChartTypeChange(selectedType) {
      this.selectedChartType = selectedType;
    },
    handleXAxisSelected({ data, displayName }) {
      this.xAxisData = data;
      this.xAxisDisplayName = displayName;
    },
    handleYAxisSelected({ data, displayNames }) {
      this.yAxisData = data;
      this.yAxisDisplayNames = displayNames;
    },
    handleZAxisSelected({ data, displayName }) {
      this.zAxisData = data;
      this.zAxisDisplayName = displayName;
      this.markerSizes = [...this.zAxisData];
    },
    handleXColorGroupSelected(groupedXAxisData) {
      this.xColorGroupData = groupedXAxisData;
    },
    handleYColorGroupSelected(groupedYAxisData) {
      this.yColorGroupData = groupedYAxisData;
    },
    handleZColorGroupSelected(groupedZAxisData) {
      this.zColorGroupData = groupedZAxisData;
    },
    handleColorGroupKey(selectedColorGroupKey) {
      this.colorGroupKey = selectedColorGroupKey
    },
    handleSavedCharts(title) {
      this.$emit("save-chart", title)
    },
    handleSampleError(sampleError) {
      this.sampleError = sampleError
    },
  }
};
</script>