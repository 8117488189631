<template>
  <div class="studio-page-wrapper" :style="rightContentStyle">
    <ChartInputs />
    <Filters @drawerStateChanged="handleRightDrawerStateChange" />
  </div>
</template>
      
  <script>
import ChartInputs from "@/components/layout/Dashboard/ChartInputs.vue";
import Filters from "@/components/layout/Common/Filters.vue";
import screenWidthMixin from "../../assets/JS/screenWidthMixin";
export default {
  components: { ChartInputs, Filters },
  name: "Main",
  mixins: [screenWidthMixin],
};
</script>