<template>
  <div>
    <!-- <div>
      <v-switch
      v-if="cvdComposition && cvdComposition.length > 0 && !loading"
        v-model="toggleFraction"
        color="info"
        :label="toggleFraction ? 'Mole %' : 'Mass %'"
        @change="toggleFractionFunction"
      ></v-switch>
    </div> -->
    <v-row no-gutters>
      <v-col cols="12" md="6">
        <ProducedGasPressureChart :data="getChartData" :loading="loading" :toggleFraction="toggleFraction" />
      </v-col>
      <v-col cols="12" md="6">
        <ProducedGasComponentChart :data="getChartData" :loading="loading" :toggleFraction="toggleFraction" />
      </v-col>
    </v-row>
    <div v-if="cvdComposition && cvdComposition.length > 0 && !loading">
      <!-- <v-select
        v-model="selectedSort"
        label="Sort Step Pressure"
        :items="sortOptions"
        @update:modelValue="onSelectSort"
      ></v-select> -->

      <div class="table-wrap">
        <div class="table-title-div">
          <p class="table-title">Produced Gas Compositions</p>
          <div class="csv-btn" @click="downloadTableCSV">
            <img class="pointer" :src="documentDownload" alt="" />
            <div>
              Download CSV
            </div>
          </div>
        </div>
        <table class="data-table">
          <tr>
            <td>Pressure (psia)</td>
            <td v-for="component in uniqueStepPressure" :key="component">
              {{ component }}
            </td>
          </tr>
          <tr>
            <td>Component</td>
            <td v-for="component in uniqueStepPressure" :key="component">
              <!-- {{ toggleFraction ? 'Mole%' : 'Mass%' }} -->
              Mole%
            </td>
          </tr>
          <tbody>
            <tr v-for="component in uniqueFluidComponents" :key="component">
              <td>{{ component }}</td>
              <td v-for="(entry) in cvdComposition.filter(
                (entry) => entry.fluidComponentReference === component
              )" :key="entry.stepPressure">
                <!-- {{
                  toggleFraction
                    ? entry.vapor_MoleFraction !== undefined
                      ? entry.vapor_MoleFraction
                      : '-'
                    : entry.vapor_MassFraction !== undefined
                      ? entry.vapor_MassFraction
                      : '-'
                }} -->
                {{
                  entry.vapor_MoleFraction !== undefined
                    ? entry.vapor_MoleFraction
                : '-'
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else-if="!loading && !cvdComposition" class="text-center pt-15">
      <h4>Table data is not available.</h4>
    </div>
    <div v-else-if="loading">
      <screen-loader></screen-loader>
    </div>
  </div>
</template>

<script>
import ProducedGasPressureChart from "./ProducedGasPressureChart.vue";
import ProducedGasComponentChart from "./ProducedGasComponentChart.vue";
import axios from "axios";
import ScreenLoader from "../Loaders/ScreenLoader.vue";

export default {
  name: "CvdTestProperties",
  components: {
    ProducedGasComponentChart,
    ProducedGasPressureChart,
    ScreenLoader,
  },
  data: () => ({
    cvdComposition: [],
    tenantId: localStorage.getItem("tenantId"),
    loading: true,
    // toggleFraction: true,
    documentDownload: require("../../../assets/Images/csv download.png"),
    // selectedSort: "High to Low",
    // sortOptions: ["High to Low", "Low to High"],
  }),
  computed: {
    uniqueFluidComponents() {
      if (!this.cvdComposition) {
        return;
      }
      return [
        ...new Set(
          this.cvdComposition.map((entry) => entry.fluidComponentReference)
        ),
      ];
    },
    uniqueStepPressure() {
      if (!this.cvdComposition) {
        return;
      }
      return [
        ...new Set(this.cvdComposition.map((entry) => entry.stepPressure)),
      ];
    },
    getChartData() {
      if (!this.cvdComposition) {
        return;
      }
      return this.cvdComposition.map((entry) => ({
        fluidComponentReference: entry.fluidComponentReference,
        stepPressure: entry.stepPressure,
        vaporFraction: entry.vapor_MoleFraction,
      }));
    },
  },
  methods: {
    onSelectSort() {
      this.getTableData();
    },
    // toggleFractionFunction() {
    //   this.getTableData();
    // },
    getTableData() {
      var sampleId = localStorage.getItem("sampleId");
      var cvdId = localStorage.getItem("testId");
      this.loading = true;
      axios
        .get(
          process.env.VUE_APP_API_URL +
          `public/api/v1/samples/${sampleId}/cvdtests/${cvdId}`,
          {
            // params: {
            //   sortOrder: this.selectedSort === "High to Low" ? 0 : 1,
            // },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": this.tenantId,
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.cvdComposition = response.data.data.cvdtest.cvdComposition;
            // if (this.selectedSort === "High to Low") {
            //   this.cvdComposition.sort((a, b) => b.stepPressure - a.stepPressure);
            // } else {
            //   this.cvdComposition.sort((a, b) => a.stepPressure - b.stepPressure);
            // }
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getTableData();
  },
};
</script>