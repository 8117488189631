<template>
  <div class="chart-selection-wrap pb-4 mt-4">
    <ScreenLoader v-if="showLoader == true"></ScreenLoader>
    <v-form v-else>
      <div class="dashbaord-top-wrap">
        <v-row no-gutters>
          <v-col cols="12" md="6" v-if="showLoader == true">
            <ScreenLoader></ScreenLoader>
          </v-col>
          <!-- <v-col cols="12" md="6" class="pa-0 pl-md-0 py-md-0 dash-input" v-else-if="this.dashboardsArr.length != 0">
            <v-select v-model="dashboard" :items="dashboardName" item-text="Name"
              @update:modelValue="() => changeDashboard()" placeholder="Select saved dashboard">
            </v-select>
          </v-col> -->
          <v-col cols="12" md="6" class="d-flex align-center justify-center" v-if="timeoutMessage">
            <div class=" timeout">
              <div class="text-center">
                {{ timeoutMessage }}
                <v-icon flat @click="getSavedDashboards()" color="blue-darken-3" icon="mdi-reload"
                  size="large"></v-icon>
              </div>
            </div>
          </v-col>
          <div class="pa-0 edit-delete-dashboard" v-if="dashboardAccessLevel == 'private'">
            <v-checkbox label="Make Default" color="info"></v-checkbox>
            <div v-if="showChartInput && [
              chart_one,
              chart_two,
              chart_three,
              chart_four,
              chart_five,
              chart_six
            ].filter(chart => chart != null && chart != '').length < 6">
              <v-btn block flat class="main-btn text-none" @click="openStudioDataDialog">
                <img :src="add" alt="new-chart">
                <span>Create New Chart</span>
              </v-btn>
            </div>
            <v-btn variant class="save-btn" v-if="showEditBtn" @click="openEdit">
              <img :src="pencil" alt="edit"/>
              <span>Edit</span>
            </v-btn>
            <v-btn v-if="showCancelBtn" variant class="save-btn"
              @click="closeEdit">
              <img :src="cancel" alt="cancel"/>
              <span>Cancel</span>
            </v-btn>
            <DeleteDashboard :dashboard_id="dashboard_id" @dashboard-delete="reloadDashboards"/>
          </div>
        </v-row>

        <dashboard-charts class="mt-2" v-if="showDashboardLoader"></dashboard-charts>
        <h6 class="main-heading mb-2 text-capitalize" v-else-if="showTitle">
          {{ dashboard }}
        </h6>
        <v-row no-gutters class="my-3" v-else-if="editName">
          <v-col cols="12" class="pa-0 pb-2 pl-md-0 py-md-0">
            <label for="">Edit Dashboard Name</label>
            <v-text-field v-model="dashboard_name" required placeholder="Edit Name" hide-details
              outlined></v-text-field>
          </v-col>
        </v-row>
      </div>
      <ChartsData v-if="isDataFetched && showCharts" :chartTypes="chartTypes" />
      <!-- <DashboardChartsData v-if="isDataFetched && showCharts" :chartTypes="chartTypes" :dashboard_id="dashboard_id" /> -->
      <v-row no-gutters v-if="showCharts == false">
        <v-col cols="12" md="6" class="pa-0 pl-md-0 py-md-0">
          <label for="">Select Saved Chart</label>
          <v-select clearable v-model="chart_one" item-value="Name" item-text="Name" placeholder="Select Chart"
            :items="chartTypesName" @update:modelValue="(value) => chartTypeChange(value, 0)">
          </v-select>
        </v-col>
        <v-col cols="12" md="6" class="pa-0 pl-md-3">
          <label for="">Select Saved Chart</label>
          <v-select clearable v-model="chart_two" item-value="Name" item-text="Name" placeholder="Select Chart"
            :items="chartTypesName" @update:modelValue="(value) => chartTypeChange(value, 1)"></v-select>
        </v-col>
        <v-col cols="12" md="6" class="pa-0 pl-md-0 py-md-0">
          <label for="">Select Saved Chart</label>
          <v-select clearable v-model="chart_three" item-value="Name" item-text="Name" placeholder="Select Chart"
            :items="chartTypesName" @update:modelValue="(value) => chartTypeChange(value, 2)"></v-select>
        </v-col>
        <v-col cols="12" md="6" class="pa-0 pl-md-3">
          <label for="">Select Saved Chart</label>
          <v-select clearable v-model="chart_four" item-value="Name" item-text="Name" placeholder="Select Chart"
            :items="chartTypesName" @update:modelValue="(value) => chartTypeChange(value, 3)"></v-select>
        </v-col>
        <v-col cols="12" md="6" class="pa-0 pl-md-0 py-md-0">
          <label for="">Select Saved Chart</label>
          <v-select clearable v-model="chart_five" item-value="Name" item-text="Name" placeholder="Select Chart"
            :items="chartTypesName" @update:modelValue="(value) => chartTypeChange(value, 4)"></v-select>
        </v-col>
        <v-col cols="12" md="6" class="pa-0 pl-md-3">
          <label for="">Select Saved Chart</label>
          <v-select clearable v-model="chart_six" item-value="Name" item-text="Name" :items="chartTypesName"
            placeholder="Select Chart" @update:modelValue="(value) => chartTypeChange(value, 5)"></v-select>
        </v-col>
      </v-row>
       <div class="save-dash" v-if="showCharts == false">
          <v-btn
            block
            flat
            @click="openDialog()"
            :disabled="isUpdateDisabled"
            class="save-btn text-none"
          >
            <img :src="saveDashboard" alt="update_dashboard" />
            <span>Save Dashboard Settings</span></v-btn
          >
        </div>
    </v-form>
    <v-dialog v-model="showStudioDataDialog" width="100vw">
      <v-card>
        <div class="modal-header header-div">
          <v-spacer></v-spacer>
          <v-btn @click="closeModal" flat icon size="x-small">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <StudioData @save-chart="handleChartSavedStudio" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="showAlert" color="#E53535" timeout="5000">
      Error : {{ errorMessage }}
    </v-snackbar>
    <ConfirmUpdateDashboard
      ref="childComponent"
      @open-dialog="openDialog"
      @dashboard-update="updateDashboard"
    />
  </div>
</template>

<script>
import axios from "axios";
import api from "../../../axiosInterceptor"
import ScreenLoader from "../Loaders/ScreenLoader.vue";
import StudioData from '../FluidsDataStudio/StudioData.vue';
import ChartsData from "./ChartsData.vue";
// import DashboardChartsData from "./DashboardChartsData.vue";
import DeleteDashboard from "./DeleteDashboard"
import DashboardCharts from '../Loaders/DashboardCharts.vue';
import ConfirmUpdateDashboard from "../Dashboard/ConfirmUpdateDashboard.vue"

export default {
  name: "BoardSelected",
  components: {
    ScreenLoader,
    StudioData,
    ChartsData,
    // DashboardChartsData,
    DeleteDashboard,
    DashboardCharts,
    ConfirmUpdateDashboard
  },
  data() {
    return {
      showLoader: true,
      dashboardName: [],
      dashboardsArr: [],
      tenantId: localStorage.getItem("tenantId"),
      dashboard: null,
      chartTypes: [],
      chartTypesName: [],
      chart_one: null,
      chart_two: null,
      chart_three: null,
      chart_four: null,
      chart_five: null,
      chart_six: null,
      dashboard_id: "",
      newDashboard: [],
      showChartInput: false,
      showSaveBtn: false,
      showTitle: true,
      editName: false,
      dashboard_name: "",
      selectedChartIds: [],
      loading: false,
      isDataFetched: false,
      showCharts: true,
      showStudioDataDialog: false,
      showAlert: false,
      errorMessage: "",

      timeoutMessage: "",
      showDashboardLoader: true,
      showEditBtn: true,
      showCancelBtn: false,
      dashboardAccessLevel: "",
      pencil: require("../../../assets/Images/Dashboard/pencil.png"),
      cancel: require("../../../assets/Images/Dashboard/Delete.png"),
      add: require("../../../assets/Images/Navbar/white-add.png"),
      saveDashboard: require("../../../assets/Images/Dashboard/save.png"), 
    };
  },
  computed: {
    isUpdateDisabled() {
      return (
        !this.dashboard_name ||
        !(
          this.chart_one ||
          this.chart_two ||
          this.chart_three ||
          this.chart_four ||
          this.chart_five ||
          this.chart_six
        )
      );
    },
  },
  methods: {
    openDialog() {
      if (this.$refs.childComponent) {
        this.$refs.childComponent.openDialog();
      } else {
        console.error("Child component not available");
      }
    },
    openStudioDataDialog() {
      this.showStudioDataDialog = true;
    },
    closeModal() {
      this.showStudioDataDialog = false;
    },
    handleChartSavedStudio(newChart) {
      this.getSavedCharts();
      // Add the new chart to chartTypes and chartTypesName
      this.chartTypes.push(newChart);
      this.chartTypesName.push(newChart.name);

      // Find the first empty chart slot and set the new chart there
      if (!this.chart_one) {
        this.chart_one = newChart.name;
      } else if (!this.chart_two) {
        this.chart_two = newChart.name;
      } else if (!this.chart_three) {
        this.chart_three = newChart.name;
      } else if (!this.chart_four) {
        this.chart_four = newChart.name;
      } else if (!this.chart_five) {
        this.chart_five = newChart.name;
      } else if (!this.chart_six) {
        this.chart_six = newChart.name;
      }
      this.closeModal();
    },
    getSavedCharts() {
      this.showLoader = true;
      const tenantId = localStorage.getItem("tenantId");
      axios
        .get(process.env.VUE_APP_API_URL + "public/api/v1/charts", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "X-TenantID": tenantId,
          },
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.chartTypes = response.data.data.charts;
            // this.chartTypesName = response.data.data.charts.map(
            //   (chart) => chart.Name
            // );
            this.chartTypesName = [
              ...this.chartTypes.map((chart) => chart.Name),
              "+ Create New Chart"
            ];
            this.showChartInput = true;
            this.showSaveBtn = true;
            this.showTitle = false;
            this.editName = true;
            this.showCharts = false;
          }
        })
        .catch((error) => {
          console.log("Error", error);
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    updateSelectedChartIds(chartId) {
      const index = this.selectedChartIds.indexOf(chartId);
      if (index === -1) {
        this.selectedChartIds.push(chartId);
      } else {
        this.selectedChartIds.splice(index, 1);
      }
    },

    chartTypeChange(selectedChartName, chartIndex) {
      const selectedChart = this.chartTypes.find(
        (chart) => chart.Name === selectedChartName
      );

      if (selectedChart) {
        this.updateSelectedChartIds[chartIndex] = selectedChart.ID;
      }

      let availableCharts = this.chartTypes.filter(chart => !this.selectedChartIds.includes(chart.ID));
      this.chartTypesName = availableCharts.map(chart => chart.Name).concat("+ Create New Chart");

      if (selectedChartName === "+ Create New Chart") {
        // If "Create New Chart" is selected, trigger createNewChart method
        this.openStudioDataDialog()
        if(selectedChartName === this.chart_one) {
          this.chart_one = null;
        }
        if(selectedChartName === this.chart_two) {
          this.chart_two = null;
        }
        if(selectedChartName === this.chart_three) {
          this.chart_three = null;
        }
        if(selectedChartName === this.chart_four) {
          this.chart_four = null;
        }
        if(selectedChartName === this.chart_five) {
          this.chart_five = null;
        }
        if(selectedChartName === this.chart_six) {
          this.chart_six = null;
        }
      }
    },

    openEdit() {
      this.getSavedCharts();
      this.showEditBtn = false;
      this.showCancelBtn = true;
    },
    closeEdit() {
      this.showChartInput = false;
      this.showSaveBtn = false;
      this.showTitle = true;
      this.editName = false;
      this.showCharts = true;
      this.getSavedDashboards();
      this.fetchData();
      this.showCancelBtn = false;
      this.showEditBtn = true
    },
    getSavedDashboards() {
      this.showLoader = true;
      this.timeoutMessage = "";
      api
        .get("public/api/v1/dashboards")
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.dashboardsArr = response.data.data.dashboards;
            this.dashboardName = response.data.data.dashboards.map(
              (dashboard) => dashboard.name
            );
          }
        })
        .catch((error) => {
          this.timeoutMessage = error;
        })
        .finally(() => {
          this.showLoader = false;
        });
    },

    fetchData() {
      this.showLoader = true;
      // this.dashboardName = this.$route.params.name.replace(/-/g, " ");
      this.dashboardName = localStorage.getItem("dashboard");
      const dashboardString = JSON.stringify(this.dashboardName);
      this.showDashboardLoader = true;
      axios
        .get(
          process.env.VUE_APP_API_URL +
          "public/api/v1/dashboards?name=" +
          encodeURIComponent(dashboardString),
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": this.tenantId,
            },

          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            const dashboardData = response.data.data.dashboards[0];
            this.dashboard = dashboardData.name;
            this.dashboard_name = dashboardData.name;
            this.dashboard_id = dashboardData.ID;
            this.dashboardAccessLevel = dashboardData.AccessLevel;
            this.chartTypes = dashboardData.Charts;
            this.chartTypesName = this.chartTypes.map((chart) => chart.Name);
            this.selectedChartIds = this.chartTypes.map((chart) => chart.ID);  // Initialize selectedChartIds
            this.chart_one = this.chartTypesName[0];
            this.chart_two = this.chartTypesName[1];
            this.chart_three = this.chartTypesName[2];
            this.chart_four = this.chartTypesName[3];
            this.chart_five = this.chartTypesName[4];
            this.chart_six = this.chartTypesName[5];
            this.isDataFetched = true;
          }
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED") {
            this.timeoutMessage = `Request timeout. Try again`;
          }
        })
        .finally(() => {
          this.showDashboardLoader = false;
        });
    },

    fetchDataOnChange(newDashboardName) {
      this.showLoader = true;
      // this.newDashboard = newDashboardName.replace(/-/g, " ");
      // const dashboardString = JSON.stringify(this.newDashboard);
      this.newDashboard = localStorage.getItem("dashboard");
      const dashboardString = JSON.stringify(this.newDashboard);
      axios
        .get(
          process.env.VUE_APP_API_URL +
          "public/api/v1/dashboards?name=" +
          encodeURIComponent(dashboardString),
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": this.tenantId,
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.showChartInput = false;
            this.showSaveBtn = false;
            this.showTitle = true;
            this.showCharts = true;
            this.editName = false;
            this.showEditBtn = true;
            this.showCancelBtn = false;
            this.dashboard = response.data.data.dashboards[0].name;
            this.dashboard_name = response.data.data.dashboards[0].name;
            this.dashboard_id = response.data.data.dashboards[0].ID;
            this.dashboardAccessLevel = response.data.data.dashboards[0].AccessLevel;
            this.chartTypes = response.data.data.dashboards[0].Charts;
            this.chartTypesName = this.chartTypes.map((chart) => chart.Name);
            this.chart_one = this.chartTypesName[0];
            this.chart_two = this.chartTypesName[1];
            this.chart_three = this.chartTypesName[2];
            this.chart_four = this.chartTypesName[3];
            this.chart_five = this.chartTypesName[4];
            this.chart_six = this.chartTypesName[5];
            this.isDataFetched = true;
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log("error");
          }
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    changeDashboard() {
      const selectedDashboard = this.dashboardsArr.find(
        (dashboard) => dashboard.name === this.dashboard
      );

      if (selectedDashboard.name) {
        // const newDashboardName = selectedDashboard.name.replace(/\s/g, "-");
        // this.$router.push({
        //   name: "Board",
        //   params: { name: newDashboardName },
        // });
        this.fetchDataOnChange(selectedDashboard.name);
      }
    },
     updateDashboard() {
      const tenantId = localStorage.getItem("tenantId");
      const dashboardId = this.dashboard_id;

      this.selectedChartIds = [
        this.chart_one,
        this.chart_two,
        this.chart_three,
        this.chart_four,
        this.chart_five,
        this.chart_six
      ].map(chartName => {
        const chart = this.chartTypes.find(chart => chart.Name === chartName);
        return chart ? chart.ID : null;
      }).filter(id => id !== null);

      this.loading = true;

      const requestBody = {
        Data: {
          dashboard: {
            name: this.dashboard_name,
            chartsID: this.selectedChartIds,
            AccessLevel: "private",
          },
        },
      };

      axios
        .put(
          process.env.VUE_APP_API_URL + "public/api/v1/dashboards/" + dashboardId,
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": tenantId,
            },
          }
        )
        .then((response) => {
          if (response.data.statusCode === 200) {
            if(this.dashboard_name) {
              localStorage.setItem("dashboard", this.dashboard_name)
            }
            this.$emit("dashboard-name-update");
            this.getSavedDashboards();
            this.loading = false;
            this.isDataFetched = true;
            this.showChartInput = false;
            this.showSaveBtn = false;
            this.showTitle = true;
            this.showCharts = true;
            this.editName = false;
            const updateDashboardName = this.dashboard_name.replace(/\s/g, "-");
            // this.$router.push({
            //   name: "Board",
            //   params: { name: updateDashboardName },
            // });
            this.fetchDataOnChange(updateDashboardName);
          }
        })
        .catch((error) => {
          this.showAlert = true;
          this.errorMessage = error.response.data.error;
          this.loading = false;
        });
    },
    reloadDashboards() {
      this.$emit("dashboard-reload");
    },
  },
  mounted() {
    this.getSavedDashboards();
    this.fetchData();
  },
};
</script>