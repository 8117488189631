<template>
  <v-dialog
    v-model="dialog"
    width="600"
    transition="dialog-top-transition"
    class="save-dashboard"
  >
    <template v-slot:default="{ isActive }">
      <v-card>
        <v-card-title class="d-flex align-center justify-between">
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDialog"
            flat
            icon
            class="chart-preview-close-btn"
            size="x-small"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-4 mx-5">
          <h6 class="text-center">Save Dashboard</h6>
          <p class="text-center">Are you sure you want to save your changes?</p>
        </v-card-text>
        <v-card-actions class="btn-wrap">
          <v-btn class="cancel-btn" @click="isActive.value = false">
            <span>Cancel</span>
          </v-btn>
          <v-btn class="primary-btn" @click="createDashboard()" :loading="loading">
            <img class="mr-2" :src="save_btn" alt="create_dashboard" />
            <span>Save</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
  
  <script>
import axios from "axios";
export default {
  name: "ConfirmDashboard",
  data() {
    return {
      dialog: false,
      save_btn: require("../../../assets/Images/Dashboard/save-btn.png"),
      loading: false,
    };
  },
  props: {
    selectedChartIds: {
      type: Array,
    },
    name: {
      type: String,
    },
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    createDashboard() {
      const tenantId = localStorage.getItem("tenantId");

      // Push each selected chart ID into the array
      for (let i = 1; i <= 6; i++) {
        if (this[`selectedChartId${i}`]) {
          this.selectedChartIds.push(this[`selectedChartId${i}`]);
        }
      }

      const requestBody = {
        Data: {
          dashboard: {
            name: this.name,
            chartsID: this.selectedChartIds,
            AccessLevel: "private",
          },
        },
      };

      this.loading = true;

      axios
        .post(
          process.env.VUE_APP_API_URL + "public/api/v1/dashboards",
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": tenantId,
            },
          }
        )
        .then((response) => {
          if(response && response.data && response.data.statusCode === 201) {
            if(this.name) {
              localStorage.setItem("dashboard", this.name)
            }
            // Emit the event to notify parent component
          this.$emit("dashboard-saved");
          }
        })
        .catch((error) => {
          this.showAlert = true;
          this.errorMessage = error;
        })
        .finally(() => {
          this.loading = false;
          this.closeDialog();
        });
    },
  },
};
</script>