<template>
  <div class="auth-right-wrapper d-flex align-center justify-center w-100">
    <div class="wrap-content">
      <h6 class="title">Hello Again</h6>
      <v-form ref="form" v-model="form" @submit.prevent="login">
        <v-text-field
          v-model="email"
          :rules="[required, emailRule]"
          placeholder="Email Address"
          prepend-inner-icon="mdi-email-outline"
          class="mb-1"
        ></v-text-field>
        <v-text-field
          v-model="password"
          :rules="[required, passwordRule]"
          placeholder="Password"
          prepend-inner-icon="mdi-lock"
          class="mb-1"
          :type="showPassword ? 'text' : 'password'"
        >
          <template v-slot:append>
            <v-btn icon @click="togglePasswordVisibility">
              <v-icon>{{ showPassword ? "mdi-eye-off" : "mdi-eye" }}</v-icon>
            </v-btn>
          </template>
        </v-text-field>
        <v-btn
          :disabled="loading || !isFormValid"
          :loading="loading"
          block
          class="auth-btn mb-3"
          type="submit"
          :class="{ filled: isFormValid }"
        >
          <span>Login</span>
        </v-btn>
      </v-form>
      <div class="text-center">
        <v-btn class="forgot-link">Forgot Password</v-btn>
      </div>
    </div>
    <v-dialog
      v-model="showModal"
      max-width="600"
      transition="dialog-top-transition"
      persistent
    >
      <v-card>
        <v-card-title class="px-0 d-flex align-center justify-between"
          >Tenant Data
          <v-spacer></v-spacer>
          <v-btn
            @click="closeModal"
            flat
            icon
            class="chart-preview-close-btn"
            size="x-small"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="height: 200px">
          <div
            v-if="showLoader == true"
            class="tenant-sec"
          >
            <v-progress-circular
              color="primary"
              size="48"
              indeterminate
            ></v-progress-circular>
          </div>
          <v-select
            v-else-if="
              getTenantData && getTenantData.length > 1 && showLoader == false
            "
            @update:modelValue="onSelectTenant"
            v-model="selectedTenant"
            :items="getTenantData.map((tenant) => tenant.name)"
            placeholder="Please select tenant"
          >
          </v-select>
          <div
            v-else-if="getTenantError && showLoader == false"
            class="tenant-sec timeout"
          >
            <div class="text-center" v-html="getTenantError"></div>
            <v-btn flat @click="callTenants()" class="save-btn text-none"
              >Reload</v-btn
            >
          </div>
          <v-list v-else>
            <v-list-item> No tenant data available. </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      transition="dialog-top-transition"
      width="400"
      v-model="showAlert"
      persistent
    >
      <v-alert
        title="Previous Session Detected"
      >
      <p class="session-info">Would you like to continue from where you left off ?</p>
        <div class="alert-btns-wrap">
          <v-btn class="text-none cancel-btn" text @click="goToAssets">No</v-btn>
          <v-btn class="text-none save-btn" @click="goToLastView">Yes</v-btn>
        </div>
      </v-alert>
    </v-dialog>
    <v-snackbar
      v-model="snackbarMessage"
      color="#E53535"
      timeout="3000"
      v-if="getLoginError"
    >
      Error : {{ getLoginError }}
    </v-snackbar>
    <v-snackbar
      v-model="timeoutMessage"
      color="#E53535"
      timeout="3000"
      v-if="getLoginError"
    >
      <div class="text-center" v-html="getLoginError"></div>
    </v-snackbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { fetchMetaData } from '../../../utils/cache';
export default {
  name: "LoginSide",
  data: () => ({
    form: false,
    email: null,
    password: null,
    loading: false,
    showModal: false,
    selectedTenant: null,
    snackbarMessage: null,
    timeoutMessage: null,
    showPassword: false,
    showAlert: false,
    showLoader: true,
  }),
  computed: {
    ...mapGetters("tenantId", ["getTenantData", "getSelectedTenant"]),
    getLoginError() {
      const error = this.$store.getters["auth/getLoginError"];
      if (typeof error === 'string' && error && error.includes("Request timeout. Try again")) {
        this.timeoutMessage = error;
      } else {
        this.snackbarMessage = error;
      }
      return error;
    },
    getTenantError() {
      const error = this.$store.getters["tenantId/getTenantError"];
      if (error && error.includes("Request timeout. Try again")) {
        return error;
      }
    },
    passwordRule() {
      return (v) => {
        if (!v) return "Password is required";
        if (v.length < 8 || v.length > 15)
          return "Password must be between 8 and 15 characters";
        return true;
      };
    },
    emailRule() {
      return (v) => {
        if (!v) return "Email is required";
        const validEmail = this.validateEmail(v);
        if (!validEmail) return "Invalid email address";
        return true;
      };
    },
    isFormValid() {
      const isEmailValid = this.emailRule(this.email) === true;
      const isPasswordValid = this.passwordRule(this.password) === true;
      const isValid = isEmailValid && isPasswordValid;
      return isValid;
    },
  },
  methods: {
    ...mapActions("auth", ["sendLoginRequest", "sendLogoutRequest"]),
    // ...mapActions("metaData", ["fetchMetaData"]),
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    login() {
      this.loading = true;
      this.sendLoginRequest({
        username: this.email,
        password: this.password,
      })
        .then(async (response) => {
          if (response?.data?.statusCode === 200) {
            await this.callTenants();
          }
        })
        .catch((error) => {
          console.log("Error in login:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    callTenants() {
      this.showModal = true;
      this.showLoader = true;
      this.$store
        .dispatch("tenantId/fetchTenants")
        .then(async () => {
          var existingTenantId = localStorage.getItem("tenantId");
          if (this.getTenantData && this.getTenantData.length === 1) {
            localStorage.setItem("tenantId", this.getTenantData[0].ID);
            localStorage.setItem("tenantName", this.getTenantData[0].name);
            await fetchMetaData();
            if (existingTenantId === this.getTenantData[0].ID) {
              // If the selected tenant ID matches the existing tenant ID, show the confirmation message
              var lastView = localStorage.getItem("lastView");
              if (lastView) {
                this.showAlert = true;
              } else {
                this.$router.push("/assets");
              }
            } else {
              // If the selected tenant ID does not match the existing tenant ID, navigate to /assets
              this.$router.push("/assets");
            }
          } else if (this.getTenantData && this.getTenantData.length > 1) {
            // this.showModal = true;
          }
        })
        .catch((error) => {
          console.error("Error fetching tenants:", error);
        })
        .finally(() => {
          this.loading = false;
          this.showLoader = false;
        });
    },

    logOut() {
      this.sendLogoutRequest()
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.$router.push("/login");
          }
        })
        .catch((error) => {
          console.error("Error in logout:", error);
        });
    },

    async onSelectTenant(selectedValue) {
      const selectedTenant = this.getTenantData.find(
        (tenant) => tenant.name === selectedValue
      );

      if (selectedTenant) {
        const { name, ID } = selectedTenant;
        var existingTenantId = localStorage.getItem("tenantId"); // Retrieve the existing tenant ID from local storage

        localStorage.setItem("tenantId", ID);
        localStorage.setItem("tenantName", name);
        this.$store.commit("tenantId/SET_SELECTED_TENANT", { name, ID });
        await fetchMetaData();
        if (existingTenantId === ID) {
          this.showModal = false;
          // If the selected tenant ID matches the existing tenant ID, show the confirmation message
          var lastView = localStorage.getItem("lastView");
          if (lastView) {
            this.showAlert = true;
          } else {
            this.$router.push("/assets");
          }
        } else {
          // If the selected tenant ID does not match the existing tenant ID, navigate to /assets
          this.$router.push("/assets");
        }
      }
    },

    goToLastView() {
      var lastView = localStorage.getItem("lastView");
      this.showAlert = false;
      if (lastView) {
        this.$router.push(lastView);
      } else {
        this.$router.push("/assets");
      }
    },
    goToAssets() {
      this.showAlert = false;
      this.$router.push("/assets");
    },

    required(v) {
      return !!v || "Field is required";
    },
    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    closeModal() {
      this.showModal = false;
      localStorage.removeItem("authToken");
    },
  },
};
</script>