import axios from "axios";
import store from "./store";
import router from "@/router";

// Create axios instance
const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 15000,
});

// Request interceptor to set headers
api.interceptors.request.use(config => {
    const token = localStorage.getItem("authToken");
    const tenantId = localStorage.getItem("tenantId");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    if (tenantId) {
        config.headers["X-TenantID"] = tenantId;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

// Response interceptor to handle errors
api.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.code === "ECONNABORTED") {
        return Promise.reject("Request timeout. Try again.");
    }
    if (error.code === "ERR_NETWORK") {
        localStorage.removeItem("authToken");
            localStorage.removeItem("tenantId");
            localStorage.removeItem("tenantName");
            router.push("/login");
    }
    if (error.response) {
        if (error.response.status === 401) {
            localStorage.removeItem("authToken");
            localStorage.removeItem("tenantId");
            localStorage.removeItem("tenantName");
            router.push("/login");
        } else if (error.response.status === 500) {
            store.commit('api/showSnackbar', {
                message: 'Api error.',
                color: '#B71C1C',
                timeout: 7000,
            });
        }
    }
    return Promise.reject(error);
});

export default api;