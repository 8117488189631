<template>
  <div>
    <div class="main-wrapper" v-if="!isLoginScreen">
      <LeftMenu @drawerStateChanged="handleDrawerStateChange" />
      <div class="right-wrapper" :style="rightSubContentStyle">
        <Navbar />
        <div class="right-sub-content">
          <!-- <Icons v-if="shouldRenderIcons" /> -->
          <router-view />
        </div>
      </div>
      <!-- <Filters @drawerStateChanged="handleRightDrawerStateChange" v-if="shouldRenderFilters" /> -->
    </div>
    <router-view v-if="isLoginScreen" />
  </div> 
</template>

<script>
import Navbar from "./Navbar.vue";
// import Icons from "./Icons.vue";
import LeftMenu from "./LeftMenu.vue";
import screenWidthMixin from "../../../assets/JS/screenWidthMixin";
// import Filters from './Filters.vue';

export default {
  name: "UniversalLayout",
  components: {
    Navbar,
    // Icons,
    LeftMenu,
    // Filters,
  },
  mixins: [screenWidthMixin],
  computed: {
    // shouldRenderFilters() {
    //   return this.shouldRenderComponent([
    //   "FluidsDataStudio"
    //   ]);
    // },
    isLoginScreen() {
      return this.$route.name === "Login";
    },
  },
  methods: {
    // shouldRenderComponent(allowedRoutes) {
    //   return allowedRoutes.includes(this.$route.name);
    // },
  },
};
</script>
