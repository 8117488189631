<template>
  <v-dialog
    v-model="dialog"
    width="600"
    transition="dialog-top-transition"
    class="save-dashboard"
  >
    <template v-slot:default="{ isActive }">
      <v-card>
        <v-card-title class="d-flex align-center justify-between">
          <v-spacer></v-spacer>
          <v-btn
            @click="closeDialog"
            flat
            icon
            class="chart-preview-close-btn"
            size="x-small"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-4 mx-5">
          <h6 class="text-center">Save Dashboard</h6>
          <p class="text-center">Are you sure you want to save your changes?</p>
        </v-card-text>
        <v-card-actions class="btn-wrap">
          <v-btn class="cancel-btn" @click="isActive.value = false">
            <span>Cancel</span>
          </v-btn>
          <v-btn class="primary-btn" @click="callUpdate()">
            <img class="mr-2" :src="save_btn" alt="update_dashboard" />
            <span>Save</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
  
  <script>
export default {
  name: "ConfirmUpdateDashboard",
  data() {
    return {
      dialog: false,
      save_btn: require("../../../assets/Images/Dashboard/save-btn.png"),
    };
  },
  props: {
  },
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    callUpdate() {
        this.$emit("dashboard-update");
        this.closeDialog()
    }
  },
};
</script>