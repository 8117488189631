<template>
  <div class="composition-page">
    <div>
      <v-switch v-if="serverItems.length > 0 && showLoaders == false" v-model="toggleFraction" color="info"
        :label="toggleFraction ? 'Mass %' : 'Mole %'" @change="toggleFractionFunction"></v-switch>
    </div>

    <chartLoaders v-if="showLoaders == true"></chartLoaders>
    <screen-loader v-if="showLoaders == true"></screen-loader>

    <v-card-text class="pa-0" v-else-if="serverItems.length > 0 && showLoaders == false">
      <div class="chart-wrap" v-if="fluidComponentReference">

        <div v-for="(variable, index) in variables" :key="index" class="chart-column">
          <div :ref="`chart-${index}`" class="main-chart">
            <div>
              <v-btn v-if="!loading && serverItems.length > 0" @click="openModal(index)" flat size="small"
                class="chart-preview-btn2 text-none">
                Expand
              </v-btn>
            </div>
          </div>

          <v-dialog v-model="isPreviewOpen[index]" width="100vw" class="multi-chart-preview-div composition">
            <v-card>
              <div class="modal-header header-div">
                <v-spacer></v-spacer>
                <v-btn @click="closeModal(index)" flat icon class="chart-preview-close-btn" size="x-small">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <v-card-text>
                <div :ref="`modal-chart-${index}`" class="modal-chart-container"></div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <div v-else-if="!fluidComponentReference" class="text-center pt-1 pb-5">
        <h4>Charts couldn't be plotted as fluid component reference is not available in this test.</h4>
      </div>
      <div class="table-wrap">
        <div class="table-title-div" v-if="serverItems.length > 0">
          <p class="table-title">Residual Oil Compositions</p>
          <div class="csv-btn" @click="downloadTableCSV">
            <img class="pointer" :src="documentDownload" alt="" />
            <div>
              Download CSV
            </div>
          </div>
        </div>
        <v-data-table v-if="showHeaders() && serverItems.length > 0" :headers="visibleHeaders" :items="visibleItems"
          :total-items="totalItems" :items-per-page="itemsPerPage" :loading="loading" :search="search"
          :item-value="name" virtual-items>
          <template v-slot:headers>
            <tr class="main-header">
              <th v-for="(header, index) in visibleHeaders" :key="index">
                {{ header.name }}
              </th>
            </tr>
            <tr class="sub-header">
              <th v-for="(header, index) in visibleHeaders" :key="index">
                {{ header.unit }}
              </th>
            </tr>
          </template>
          <template v-slot:body="{ items }">
            <tr v-for="(item, index) in items" :key="index">
              <td v-for="(header, index) in visibleHeaders" :key="index">
                <template v-if="showVariable(header.key)">
                  {{ displayValue(header.key, item[header.key]) }}
                </template>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card-text>
    <div v-else-if="(!serverItems || serverItems.length === 0) && showLoaders == false" class="tabs-no-test-div">
      <h4>No data available.</h4>
    </div>
  </div>
</template>

<script>
import Plotly from "plotly.js-dist-min";
import ChartLoaders from "../../Loaders/ChartLoaders.vue";
import ScreenLoader from "../../Loaders/ScreenLoader.vue";
import axios from "axios";
import { getDisplayName } from "../../../../utils/cache";

export default {
  name: "OilCompData",
  components: {
    ChartLoaders,
    ScreenLoader
  },
  data: () => ({
    documentDownload: require("../../../../assets/Images/csv download.png"),
    itemsPerPage: 0,
    headers: [],
    serverItems: [],
    loading: true,
    totalItems: 0,
    tenantId: localStorage.getItem("tenantId"),
    variables: ["1", "2"],
    isPreviewOpen: Array().fill(false),
    chartData: Array().fill(null),
    cachedData: {},
    testID: "",
    testName: "",
    sampleID: "",
    saturationPressure: "",
    saturationPressure_UOM: "",
    testTemperature: "",
    testTemperature_UOM: "",
    toggleFraction: true,
    showLoaders: true,
    fluidComponentReference: false,
  }),
  computed: {
    visibleHeaders() {
      const headers = [];
      this.headers.forEach((header) => {
        if (
          header.key === "fluidComponentReference" ||
          (this.toggleFraction == true &&
            header.key.includes("MassFraction")) ||
          (this.toggleFraction == false && header.key.includes("MoleFraction"))
        ) {
          headers.push(header);
        }
      });
      return headers;
    },

    // Computed property to filter items based on toggle state
    visibleItems() {
      return this.serverItems.map((item) => {
        const newItem = {};
        Object.keys(item).forEach((key) => {
          if (
            key === "fluidComponentReference" ||
            (this.toggleFraction == true && key.includes("MassFraction")) ||
            (this.toggleFraction == false && key.includes("MoleFraction"))
          ) {
            newItem[key] = item[key];
          }
        });
        return newItem;
      });
    },
  },
  watch: {
    $route(to, from) {
      this.chartPlotted()
    },
  },
  methods: {
    toggleFractionFunction() {
      this.serverItems = [];
      if (this.loading == true && this.serverItems.length == 0) {
        this.showLoaders = true;
      }
      this.chartPlotted();
    },
    showVariable(variable) {
      return (
        variable === "fluidComponentReference" ||
        (this.toggleFraction == true && variable.includes("MassFraction")) ||
        (this.toggleFraction == false && variable.includes("MoleFraction"))
      );
    },
    // Method to check if headers should be shown based on toggle state
    showHeaders() {
      return this.toggleFraction == true || this.toggleFraction == false;
    },

    plotCharts(variablesToPlot, ccetest) {
      variablesToPlot.forEach((variable, index) => {
        this.plotChartForVariable(variable, index, ccetest);
      });
    },

    async loadItems() {
      var sampleId = localStorage.getItem("sampleId");
      var dlId = localStorage.getItem("testId");
      this.loading = true;
      this.showLoaders = true;

      try {
        let response = await axios.get(
          process.env.VUE_APP_API_URL +
          `public/api/v1/samples/${sampleId}/dltests/${dlId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
              "X-TenantID": this.tenantId,
            },
          }
        );

        this.serverItems = response.data.data.dltest && response.data.data.dltest.dlResidComposition ? response.data.data.dltest.dlResidComposition : [];
        this.totalItems = response.data.data.dltest.length;
        this.testID = response.data.data.dltest.ID;
        this.testName = response.data.data.dltest.name;
        this.sampleID = response.data.data.dltest.sampleID;
        this.saturationPressure =
          response.data.data.dltest.saturationPressure;
        this.saturationPressure_UOM =
          response.data.data.dltest.saturationPressure_UOM;
        this.testTemperature = response.data.data.dltest.testTemperature;
        this.testTemperature_UOM =
          response.data.data.dltest.testTemperature_UOM;
        this.sendDataToParent();

        // Determine which sortBy array to use based on the toggle state
        const sortByArray = this.toggleFraction ? 'sortByMass' : 'sortByMole';

        // const sortByKeys = response.data.data.dltest.dlResidComposition.map(item => item[sortByArray])[0].flat().map(key => key.charAt(0).toLowerCase() + key.slice(1));

        let sortByKeys = [];


        if (response.data.data.dltest.dlResidComposition[0][sortByArray] && response.data.data.dltest.dlResidComposition[0][sortByArray].length > 0) {
          sortByKeys = response.data.data.dltest.dlResidComposition.map(item => item[sortByArray])[0].flat().map(key => key.charAt(0).toLowerCase() + key.slice(1));
        } else {
          // Fallback to using serverItems for headers and display names
          // Filter out keys that contain _UOM
          sortByKeys = Object.keys(this.serverItems[0] || {}).filter(
            (key) => !key.includes("_UOM")
          ).map(
            (key) => key.charAt(0).toLowerCase() + key.slice(1)
          );
        }

        // Check if serverItems contains fluidComponentReference
        this.fluidComponentReference = this.serverItems.some(item => item.hasOwnProperty('fluidComponentReference'));

        const availableKeys = new Set(
          sortByKeys.filter((key) =>
            this.serverItems.some((item) => key in item)
          )
        );

        const allKeys = sortByKeys.filter((key) => availableKeys.has(key));

        // Update variables array with extracted keys, excluding fluidComponentReference
        // this.variables = Array.from(allKeys).filter(key => key !== 'fluidComponentReference');

        this.variables = Array.from(allKeys).filter(
          (key) =>
            (key !== "fluidComponentReference" &&
              this.toggleFraction == true &&
              key.includes("MassFraction")) ||
            (this.toggleFraction == false && key.includes("MoleFraction"))
        );

        const allUniqueKeys = Array.from(allKeys);

        const displayNames = [];
        const digitsArray = [];

        await Promise.all(allUniqueKeys.map(async (key) => {
          const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
          const { displayName, digits } = await getDisplayName('DLResidComposition', capitalizedKey);
          displayNames.push(displayName);
          digitsArray.push(digits);
        }));

        // Update the headers array to use display names
        this.headers = Array.from(allKeys).map((key, index) => {
          const uom = this.serverItems.find((item) => item[key + "_UOM"]);
          const name = displayNames[index];
          let unit = uom ? uom[key + "_UOM"] : '-';
          // Check if unit is empty or equals "data-not-available", then set it to "-"
          if (!unit || unit === "data-not-available") {
            unit = "-";
          }
          const digits = digitsArray[index] || 0;
          return {
            key: key,
            name: name,
            unit: unit,
            digits: digits,
          };
        });

        return response.data.data.dltest;
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
        this.showLoaders = false;
      }
    },

    async plotChartForVariable(variable, chartIndex, dltest) {
      if (!dltest) {
        return;
      }

      // Filter out "H2" from the composition
      const filteredComposition = dltest.dlResidComposition.filter(step => step.fluidComponentReference !== 'H2');

      const fluidComponents = filteredComposition.map((step) => step.fluidComponentReference);
      const values = filteredComposition.map((step) => step[variable]);

      const trace = {
        x: fluidComponents,
        y: values,
        type: "scatter",
        mode: "lines+markers",
        name: variable,
      };

      const layout = {
        title: {
          text: this.getHeaderDisplayName(variable),
          font: {
            size: 12,
          },
        },
        xaxis: {
          title: {
            text: "Component",
          },
          tickangle: 45
        },
        yaxis: {
          title: this.getHeaderDisplayName(variable),
        },
      };
      const config = {
        responsive: true,
        displaylogo: false,
        modeBarButtonsToRemove: ['lasso2d', 'select2d', 'pan2d'],
        modeBarButtonsToAdd: [
          {
            name: 'Download CSV',
            icon: {
              path: 'M12 15.5l-5-5h3v-6h4v6h3l-5 5zm-10 3h20v2h-20z',
            },
            click: this.handleDownloadCSV.bind(this, chartIndex)
          }
        ],
      };

      this.chartData[chartIndex] = {
        trace: trace,
        layout: layout,
        config: config,
      };

      const chartRef = this.$refs["chart-" + chartIndex];
      if (chartRef && chartRef.length > 0) {
        const chartElement = chartRef[0];
        Plotly.newPlot(chartElement, [trace], layout, config);
      } else {
        return
      }
    },
    getHeaderDisplayName(variable) {
      const header = this.headers.find(header => header.key === variable);
      if (header) {
        return header.unit !== '-' ? `${header.name} (${header.unit})` : header.name;
      }
      return variable;
    },

    displayValue(key, value) {
      const header = this.headers.find(header => header.key === key);
      const digits = header ? header.digits : 0; // Get digits from header

      if (value !== null && value !== undefined) {
        // Format the value with the specified number of decimal places
        return digits > 0 ? value.toFixed(digits) : value;
      } else {
        return "-";
      }
    },

    handleDownloadCSV(chartIndex) {
      this.downloadChartCSV(chartIndex);
    },

    downloadChartCSV(index) {
      const chartData = this.chartData[index];

      // Check if chart data exists
      if (chartData) {
        const fileName = `${chartData.layout.yaxis.title.text} vs ${chartData.layout.xaxis.title.text} data.csv`;

        // Prepare CSV header dynamically
        const csvHeader = [
          chartData.layout.xaxis.title.text,
          chartData.layout.yaxis.title.text,
        ];

        // Prepare CSV rows
        const csvRows = chartData.trace.x.map((xValue, i) => [
          xValue,
          chartData.trace.y[i],
        ]);

        // Concatenate header and rows
        const csvContent = [
          csvHeader.join(","),
          ...csvRows.map((row) => row.join(",")),
        ].join("\n");

        // Create Blob with CSV content
        const blob = new Blob([csvContent], {
          type: "text/csv;charset=utf-8;",
        });

        // Create link element to trigger download
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName); // Set filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error(
            "Your browser does not support downloading files programmatically. Please try a different browser."
          );
        }
      }
    },

    openModal(index) {
      this.isPreviewOpen[index] = true;
      this.$nextTick(() => {
        this.plotModalChart(index);
      });
    },

    closeModal(index) {
      this.isPreviewOpen[index] = false;
    },
    plotModalChart(index) {
      const chartData = this.chartData[index];
      if (chartData) {
        const modalChartRef = this.$refs[`modal-chart-${index}`][0];
        Plotly.newPlot(
          modalChartRef,
          [chartData.trace],
          chartData.layout,
          chartData.config
        );
      }
    },
    sendDataToParent() {
      this.$emit("data-updated", {
        testID: this.testID,
        testName: this.testName,
        sampleID: this.sampleID,
        saturationPressure: this.saturationPressure,
        saturationPressure_UOM: this.saturationPressure_UOM,
        testTemperature: this.testTemperature,
        testTemperature_UOM: this.testTemperature_UOM,
      });
    },

    downloadTableCSV() {
      // Check if table has items
      if (this.serverItems && this.serverItems.length > 0) {
        const fileName = `residual_oil_compositions_table.csv`;

        // Prepare CSV header dynamically from table headers
        const csvHeader = this.headers.map((header) => header.name);
        const csvUnits = this.headers.map((header) => header.unit);

        // Prepare CSV rows from table data
        const csvRows = this.serverItems.map((item) =>
          this.headers.map((header) => item[header.key])
        );

        // Concatenate header and rows
        const csvContent = [
          csvHeader.join(","),
          csvUnits.join(","),
          ...csvRows.map((row) => row.join(",")),
        ].join("\n");

        // Create Blob with CSV content
        const blob = new Blob([csvContent], {
          type: "text/csv;charset=utf-8;",
        });

        // Create link element to trigger download
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", fileName); // Set filename
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error(
            "Your browser does not support downloading files programmatically. Please try a different browser."
          );
        }
      } else {
        console.error("No data available in the table to download.");
      }
    },

    chartPlotted() {
      this.loadItems().then((ccetest) => {
        // Determine which variables to plot based on toggle state
        const variablesToPlot = [];
        if (this.toggleFraction == true) {
          variablesToPlot.push(
            "liquid_MassFraction",
            "vapor_MassFraction",
            "overall_MassFraction",
          );
        }
        if (this.toggleFraction == false) {
          variablesToPlot.push(
            "liquid_MoleFraction",
            "vapor_MoleFraction",
            "overall_MoleFraction",
          );
        }

        if (
          JSON.stringify(variablesToPlot) !==
          JSON.stringify(this.variablesToPlot)
        ) {
          this.variablesToPlot = variablesToPlot; // Update the tracked variables to plot
          this.plotCharts(variablesToPlot, ccetest); // Plot charts only for the variables enabled by the toggle switches
        }

        // Plot charts only for the variables enabled by the toggle switches
        variablesToPlot.forEach((variable, index) => {
          this.plotChartForVariable(variable, index, ccetest);
        });
      });
    },
  },
  mounted() {
    this.chartPlotted();
  },
};
</script>