<template>
  <div class="exploration-table">
    <v-data-table-server v-model:items-per-page="itemsPerPage" :headers="headers" :items="serverItems"
      :items-length="totalItems" :loading="loading" item-value="name" @update:options="loadItems"></v-data-table-server>
  </div>
</template>

<script>
const desserts = [
  {
    name: 'Asset 1',
    calories: "Field 1",
    fat: "Well #23",
    carbs: "Oil Sample Test",
    protein: "Sample #23A",
    iron: 'Composition Analysis',
  },
  {
    name: 'Asset 2',
    calories: "Field 2",
    fat: "Well #23",
    carbs: "Oil Sample Test",
    protein: "Sample #23A",
    iron: 'CCE',
  },
  {
    name: 'Asset 3',
    calories: "Field 3",
    fat: "Well #23",
    carbs: "Oil Sample Test",
    protein: "Sample #23A",
    iron: 'DL',
  },
  {
    name: 'Asset 4',
    calories: "Field 4",
    fat: "Well #23",
    carbs: "Oil Sample Test",
    protein: "Sample #23A",
    iron: 'Separator Test',
  },
  {
    name: 'Asset 5',
    calories: "Field 5",
    fat: "Well #23",
    carbs: "Oil Sample Test",
    protein: "Sample #23A",
    iron: 'Viscosity Test',
  },
  {
    name: 'Asset 6',
    calories: "Field 6",
    fat: "Well #23",
    carbs: "Oil Sample Test",
    protein: "Sample #23A",
    iron: 'Separator Test',
  },
  {
    name: 'Asset 7',
    calories: "Field 7",
    fat: "Well #23",
    carbs: "Oil Sample Test",
    protein: "Sample #23A",
    iron: 'Composition Analysis',
  },
  {
    name: 'Asset 4',
    calories: "Field 4",
    fat: "Well #23",
    carbs: "Oil Sample Test",
    protein: "Sample #23A",
    iron: 'CCE',
  },
]

const FakeAPI = {
  async fetch({ page, itemsPerPage, sortBy }) {
    return new Promise(resolve => {
      setTimeout(() => {
        const start = (page - 1) * itemsPerPage
        const end = start + itemsPerPage
        const items = desserts.slice()

        if (sortBy.length) {
          const sortKey = sortBy[0].key
          const sortOrder = sortBy[0].order
          items.sort((a, b) => {
            const aValue = a[sortKey]
            const bValue = b[sortKey]
            return sortOrder === 'desc' ? bValue - aValue : aValue - bValue
          })
        }

        const paginated = items.slice(start, end)

        resolve({ items: paginated, total: items.length })
      }, 500)
    })
  },
}

export default {
  name: "TableData",
  data: () => ({
    itemsPerPage: 5,
    headers: [
      {
        title: 'Assets',
        //   align: 'start',
        sortable: false,
        key: 'name',
      },
      { title: 'Fields', key: 'calories', sortable: false, },
      { title: 'Wells', key: 'fat', sortable: false, },
      { title: 'Reservoir', key: 'carbs', sortable: false, },
      { title: 'Samples', key: 'protein', sortable: false, },
      { title: 'Tests', key: 'iron', sortable: false, },
    ],
    serverItems: [],
    loading: true,
    totalItems: 0,
  }),
  methods: {
    loadItems({ page, itemsPerPage, sortBy }) {
      this.loading = true
      FakeAPI.fetch({ page, itemsPerPage, sortBy }).then(({ items, total }) => {
        this.serverItems = items
        this.totalItems = total
        this.loading = false
      })
    },
  },
}
</script>