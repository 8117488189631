<template>
  <div>
    <ScreenLoader class="mb-5" v-if="showLoader == true" />
    <div v-if="showLoader == false">
    <!-- Click event to toggle v-row visibility -->
    <div @click="toggleVRow" class="recent-activity-arrow">
      <p class="recent-activity">Pre-Defined Filters</p>
      <div class="arrow-img">
        <v-img src="../../assets/Images/arrow-up.png" alt="logo" v-if="showVRow"></v-img>
        <v-img src="../../assets/Images/arrow-down.png" alt="logo" v-if="!showVRow"></v-img>
      </div>
    </div>
    <!-- v-row toggling visibility -->
    <v-row v-if="showVRow" no-gutters>
      <v-col cols="12" md="6" lg="3" v-for="(location, index) in limitedLocations" :key="location.name">
        <!-- Asset Information -->
        <v-sheet :class="{ 'first-sheet': index === 0, 'last-sheet': index === 3 }" class="sheet mb-4">
          <v-card link>
            <v-card-title class="assets_name">
              Asset: {{ location.name }}
              <div class="last-session">Last Session</div>
            </v-card-title>

            <v-card-text class="assets-data">
              <!-- Non-test fields with a single bottom border -->
              <div class="non-test-fields">
                <div v-for="(value, key) in getNonTestFields(location.counts)" :key="key" class="field-entry">
                  {{ key }} <span> {{ value }} </span>
                </div>
              </div>

              <!-- Test fields displayed below non-test fields -->
              <div class="test-fields-container">
                <div v-for="(value, key) in getTestFields(location.counts)" :key="key" class="test-field-entry">
                  {{ key }} <span> {{ value }} </span>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
  </div>
</template>

<script>
import api from "../../axiosInterceptor";
import ScreenLoader from "../layout/Loaders/ScreenLoader.vue";
import { mapGetters } from "vuex";

export default {
  name: "RecentActivity",
  components: {
    ScreenLoader,
  },
  data: () => ({
    locations: [],
    showLoader: false,
    timeoutMessage: "",
    showVRow: true,
  }),
  computed: {
    ...mapGetters("tenantId", ["getSelectedTenant"]),
    filteredAssets() {
      return this.assets.filter((asset) => asset.name);
    },
    limitedLocations() {
      return this.locations
        .filter(location => location.name)
        .slice(0, 4);
    },
  },
  methods: {
    isTestKey(key) {
      const testKeys = ["ccetests", "dltests", "cvdtests", "compositions", "viscositytests", "separatortests"];
      return testKeys.includes(key);
    },
    getTestFields(counts) {
      const testFields = {};
      for (const [key, value] of Object.entries(counts)) {
        if (this.isTestKey(key)) {
          testFields[key] = value;
        }
      }
      console.log("Test Fields:", testFields); // Log the test fields to the console
      return testFields;
    },
    // Get only the non-test fields for a location
    getNonTestFields(counts) {
      const nonTestFields = {};
      const entries = Object.entries(counts)
        .filter(([key]) => !this.isTestKey(key)) // Filter out test fields
        .slice(0, 3); // Limit to first 3 non-test fields

      entries.forEach(([key, value]) => {
        nonTestFields[key] = value;
      });
      return nonTestFields;
    },
    toggleVRow() {
      this.showVRow = !this.showVRow;
    },
    getAllAssets() {
      this.showLoader = true;
      this.assets = [];
      this.timeoutMessage = "";

      api
        .get("public/api/v1/locations/assets")
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.locations = response.data.data.locations.sort((a, b) => {
              const nameA = a.name ? a.name.toLowerCase() : "";
              const nameB = b.name ? b.name.toLowerCase() : "";
              return nameA.localeCompare(nameB);
            });
          }
        })
        .catch((error) => {
          this.timeoutMessage = error;
        })
        .finally(() => {
          this.showLoader = false;
        });
    },
    navigateToFields(asset) {
      const assetName = asset.name.replace(/\s/g, "-");
      const sanitizedAssetName = assetName.replace(/\//g, "\\");
      this.$router.push({
        name: "Fields",
        params: { asset: sanitizedAssetName },
      });
    },
  },
  watch: {
    getSelectedTenant: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getAllAssets();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.getAllAssets
  }
};
</script>